import { Auth } from '@/utils';
import { isHttpStatusOK, parseFailed } from '@/apis/utils';
import { parseShipping } from './utils';
import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function getBuyersShipping(saleId) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(`${GO_ENDPOINT}/v1/sales/${saleId}/shipping`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseJson = await response.json();

    if (isHttpStatusOK(response.status)) {
      return parseShipping(responseJson);
    }

    throw new Error(parseFailed(responseJson.errors[0]));
  } catch (error) {
    return error;
  }
}

export default getBuyersShipping;
