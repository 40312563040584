// eslint-disable @typescript-eslint/camelcase
import { parseUser } from './utils';
import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function putUser(userId, data) {
  const token = await Auth.getIdToken();

  try {
    const response = await fetch(`${GO_ENDPOINT}/v1/users/${userId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        display_name: data.displayName,
        is_company: data.isCompany,
        company: data.companyName,
        company_address: data.companyAddress,
        boat: data.fishingBoat,
        prefecture: data.prefecture,
        about: data.introduction,
        avatar_url: data.thumnailImageUrl,
        is_email_refused: data.isEmailRefused,
        shipping_Provider: data.shippingProvider,
        is_email_wanted: data.isEmailWanted,
        is_bid_popup: data.isBidPopup,
        is_next_auto_bid: data.isNextAutoBid,
      }),
    });
    const responseJson = await response.json();
    if (responseJson.errors) {
      throw new Error(responseJson.errors[0].message);
    } else {
      return parseUser(responseJson);
    }
  } catch (error) {
    return error;
  }
}

export default putUser;
