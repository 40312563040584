import * as React from 'react';
import Pagination from '@/migration/pagination';
import { Loader } from 'semantic-ui-react';
import { MyProfileHeader, SalesList, TitleBar } from '@/components/Common';
import enhance from './enhance';

function ExhibitOfExhibitNow(props) {
  const {
    isLoading,
    onChangePage,
    sales: {
      mypage: { totalCount, current, pages },
      ownExhibits,
    },
    isPaginationDisabled,
    users,
  } = props;

  return (
    <div>
      <MyProfileHeader
        selledCount={ownExhibits.totalCount}
        userName={users.mydata.displayName}
        userThumbnail={users.mydata.thumnailImageUrl}
        averagePoint={users.mydata.averagePoint}
        totalEvaluationCount={users.mydata.totalEvaluationCount}
      />
      <TitleBar title="出品した魚 (未入札)" />
      <div className="common_sales_list">
        {isLoading ? (
          <Loader
            active
            size="tiny"
            className="tiny-loader"
            inline="centered"
          />
        ) : (
          <>
            {pages.active.length === 0 ? (
              <div
                style={{
                  textAlign: 'center',
                  paddingBottom: '40px',
                }}
              >
                <div>出品した魚 (未入札) は存在しません</div>
              </div>
            ) : (
              <>
                <SalesList data={pages.active} me={users.mydata} />
                <Pagination
                  totalCount={totalCount}
                  current={current}
                  pageSize={15}
                  changePage={onChangePage}
                  disabled={isPaginationDisabled}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default enhance((props) => <ExhibitOfExhibitNow {...props} />);
