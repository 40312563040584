import React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { paths, images } from '@/config';
import { NavBar } from './NavBar';
import enhance from './enhance';
import FruitSaleLink from './FruitSaleLink';

function Header(props) {
  if (!window.isAuthed) {
    return (
      <>
        <FruitSaleLink
          appendClassName="__tablet"
          pathname={props?.location?.pathname}
        />
        <header className="app__header">
          <div className="app__header-inner">
            <div className="wrapper">
              <Media
                query="(min-width: 768px)"
                render={() => (
                  <Link to={paths.landing} className="logo">
                    <Image src={images.logo_header} className="logo" />
                  </Link>
                )}
              />
              <Media
                query="(max-width: 767px)"
                render={() => (
                  <Link to={paths.landing} className="logo">
                    <Image src={images.logo_header_mobile} className="logo" />
                  </Link>
                )}
              />

              <FruitSaleLink appendClassName="__large-pc" />
              <NavBar />
            </div>
          </div>
        </header>
      </>
    );
  }

  return (
    <>
      <FruitSaleLink appendClassName="__tablet" />
      <header className="app__header">
        <div className="app__header-inner">
          <div className="wrapper">
            <Media
              query="(min-width: 768px)"
              render={() => (
                <Link to={paths.landing} className="logo">
                  <Image src={images.logo_header} className="logo" />
                </Link>
              )}
            />
            <Media
              query="(max-width: 767px)"
              render={() => (
                <Link to={paths.landing} className="logo">
                  <Image src={images.logo_header_mobile} className="logo" />
                </Link>
              )}
            />

            <FruitSaleLink appendClassName="__large-pc" />
            <NavBar />
          </div>
        </div>
      </header>
    </>
  );
}

export default enhance((props) => <Header {...props} />);
