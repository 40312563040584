import axios from 'axios';

import { Auth } from '@/utils';
import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

const resouces = 'sales';

export const putSingleImage = async (saleId, body) => {
  try {
    const token = await Auth.getIdToken();

    const { data } = await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .put(`${GO_ENDPOINT}/v1/${resouces}/${saleId}/image`, body);

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSaleImages = async (saleId) => {
  try {
    const { data } = await axios
      .create()
      .get(`${GO_ENDPOINT}/v1/${resouces}/${saleId}/image`);

    return data;
  } catch (error) {
    throw new Error(error);
  }
};
