import { isHttpStatusOK, parseFailed } from '@/apis/utils';
import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function getUserShop(userId) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(`${GO_ENDPOINT}/v1/users/${userId}/shop`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseJson = await response.json();

    if (isHttpStatusOK(response.status)) {
      // TODO: 画面表示に必要な情報を抽出する
      return responseJson;
    }

    throw new Error(parseFailed(responseJson.errors[0]));
  } catch (error) {
    return error;
  }
}

export default getUserShop;
