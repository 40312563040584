import { getEnv, Auth } from '@/utils';
import { parseFailed } from '@/apis/utils';

const { GO_ENDPOINT } = getEnv();

async function postUserCard(userId, stripeTokenId) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(`${GO_ENDPOINT}/v1/users/${userId}/cards`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        token: stripeTokenId,
      }),
    });
    const responseJson = await response.json();

    if (!responseJson.errors) {
      return {
        okdata: responseJson,
      };
    }

    throw new Error({
      faileddata: parseFailed(responseJson.errors[0]),
    });
  } catch (err) {
    throw new Error(err);
  }
}

export default postUserCard;
