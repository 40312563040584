import * as React from 'react';
import { Image } from 'semantic-ui-react';
import classNames from 'classnames';
import qs from 'qs';
import { constants } from '../../../../constants';

function FacebookShareButton(props) {
  const isDesktop = props.type === 'desktop';
  const isMobile = props.type === 'mobile';
  return (
    <a
      href={
        `${constants.SNS_SHARE_URL.facebook}?` +
        qs.stringify({
          u: window.location.href,
        })
      }
      target="_blank"
      rel="noopener noreferrer"
      className={classNames({
        grid_facebook_container: isDesktop,
      })}
    >
      <Image
        src="https://fishsale.jp/assets/fs_fish_icon_facebook_oval.svg"
        className={classNames({
          view_icon_facebook: isDesktop,
          main_icon_twitter: isMobile,
        })}
      />
    </a>
  );
}

export default FacebookShareButton;
