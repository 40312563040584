import React from 'react';
import { Button } from 'semantic-ui-react';
import { paths } from '@/config';

const NotReviewedModal = (props) => {
  const {
    modalProps: {
      notReviewedPart: { fish_kind, id },
    },
    isModal,
    handleCloseModal,
    history,
  } = props;
  return (
    <div className="not_reviewed_modal_body">
      <p className="w__caption">{fish_kind}のレビューをお願いします。</p>
      <div className="w__form">
        <div className="w__button">
          <Button
            className="cancel"
            onClick={() => {
              handleCloseModal(!isModal);
            }}
          >
            キャンセル
          </Button>
          <Button
            className="item-page"
            onClick={() => {
              handleCloseModal(!isModal);
              history.push(`${paths.fishes.root}/${id}`);
            }}
          >
            商品ページに行く
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotReviewedModal;
