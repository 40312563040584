import { connect } from 'react-redux';
import notify from '@/migration/notification';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { bindActionCreators } from 'redux';
import { salesAction, usersAction } from '@/actions';
import { constants } from '../../../../constants';

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  users: state.users.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction, ...usersAction, notify }, dispatch);

const enhance = compose(
  setDisplayName('Unrated'),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'updateLoading', true),
  withState('showModal', 'updateShowModal', false),
  withState('isPaginationDisabled', 'updatePaginationDisabled', false),

  withHandlers({
    onChangePage: (props) => async (current) => {
      const {
        getMe,
        updateMyPagePagesActive,
        updatePaginationDisabled,
        getUnratedWithPagination,
      } = props;

      try {
        const me = await getMe();

        updateMyPagePagesActive(current);
        updatePaginationDisabled(true);
        window.scrollTo(0, 0);

        getUnratedWithPagination(
          { limit: 15, page: current },
          { is_rated: false, fixed: true },
          ['+closed', '+fixed-created_at'],
          me.value.id
        );
      } catch (error) {
        console.error(error);
      }
      updatePaginationDisabled(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getMe,
        getUnratedWithPagination,
        updateLoading,
        updateMyPagePagesActive,
        getMyFollows,
      } = this.props;

      try {
        const me = await getMe();

        await getMyFollows(me.value.id);
        await getUnratedWithPagination(
          { limit: 15, page: constants.PAGINATION.INITIAL },
          {
            is_rated: false,
            fixed: true,
          },
          ['+closed', '+fixed-created_at'],
          me.value.id
        );
        updateMyPagePagesActive(constants.PAGINATION.INITIAL);
      } catch (error) {
        console.error(error);
      }
      updateLoading(false);
    },
    componentWillUnmount() {
      const { initializeSalesForMyPage } = this.props;
      initializeSalesForMyPage();
    },
  })
);

export default enhance;
