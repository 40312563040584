import { isHttpStatusOK } from '@/apis/utils';
import { parseBlame } from './utils';
import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function putUserBlame(userId, data) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(`${GO_ENDPOINT}/v1/users/${userId}/blame`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        body: data.body,
      }),
    });

    if (isHttpStatusOK(response.status)) {
      const json = await response.json();
      return parseBlame(json);
    }

    throw new Error(response);
  } catch (error) {
    throw new Error(error);
  }
}

export default putUserBlame;
