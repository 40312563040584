import Amplify, { Auth } from 'aws-amplify';
import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth';
import { getEnv } from '@/utils';

const { CLIENT_ID, APP_DOMAIN, REDIRECT_DOMAIN } = getEnv();

const config = {
  ClientId: CLIENT_ID,
  AppWebDomain: APP_DOMAIN,
  RedirectUriSignIn: REDIRECT_DOMAIN,
  RedirectUriSignOut: REDIRECT_DOMAIN,
  IdentityProvider: '',
  TokenScopesArray: [
    'profile',
    'email',
    'openid',
    'aws.cognito.signin.user.admin',
    'phone',
  ],
  AdvancedSecurityDataCollectionFlag: true,
};

/** SNS認証 */
export const signInSns = () => {
  return new Promise((resolve, reject) => {
    const url = window.location.href;

    const auth = new CognitoAuth(config);

    const urlSearchParams = new URLSearchParams(location.search);
    const isError = urlSearchParams.has('error_description');

    if (isError) {
      reject();
      return;
    }

    auth.userhandler = {
      onSuccess: async () => {
        const session = await Auth.currentSession();

        resolve(session.getIdToken().payload);
      },
      onFailure: (err) => {
        reject(err.error);
      },
    };

    // localStorageにJWTを保存
    auth.parseCognitoWebResponse(url);
  });
};

/** SNS登録 */
export const snsRegister = (snsType: 'Facebook' | 'Google') => {
  config.IdentityProvider = snsType;
  const auth = new CognitoAuth(config);

  return new Promise(() => {
    auth.useCodeGrantFlow();

    // 実際にSNSとの連携を行う (callback先に遷移される)
    auth.getSession();
  });
};

/** サインイン */
export const signIn = (username: string, password: string) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, password)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const signUp = async (
  email: string,
  password: string,
  attributes: any
) => {
  const params: {
    username: string;
    password: string;
    attributes?: any;
  } = {
    username: email,
    password: password,
  };

  if (attributes) params.attributes = attributes;

  try {
    return await Auth.signUp(params);
  } catch (error) {
    return error;
  }
};

/** セッションの確認 */
export const checkSession = async () => {
  try {
    await Auth.currentSession();

    return true;
  } catch (error) {
    return false;
  }
};

/** JWTトークンを返す */
export const getIdToken = async () => {
  try {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();

    if (!jwtToken) throw new Error('jwtエラー');

    return jwtToken;
  } catch {
    throw new Error('認証エラー');
  }
};

export const confirmForgotPassword = async (
  username: string,
  code: string,
  newPassword: string
) => {
  try {
    return await Auth.forgotPasswordSubmit(username, code, newPassword);
  } catch (error) {
    return error;
  }
};

export const confirmSignUp = async (username: string, code: string) => {
  try {
    return await Auth.confirmSignUp(username, code);
  } catch (error) {
    return error;
  }
};

export const resendSignUp = async (username: string) => {
  try {
    return await Auth.resendSignUp(username);
  } catch (error) {
    return error;
  }
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  } catch (error) {
    return error;
  }
};

export const forgotPassword = async (username: string) => {
  try {
    return await Auth.forgotPassword(username);
  } catch (error) {
    return error;
  }
};

/** INIT */
export const init = (
  region: string,
  userPoolId: string,
  userPoolWebClientId: string
): void => {
  Amplify.configure({
    Auth: {
      region,
      userPoolId,
      userPoolWebClientId,
    },
  });
};
