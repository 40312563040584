import React from 'react';
import { Link } from 'react-router-dom';
import { useSection } from './useSection';
import { getLocaleStringLocales, paymentMethod } from '@/utils';

import { paths } from '@/config';

const DetailSection = ({
  sellerName,
  size,
  weight,
  weightAdditionalInfo,
  spotText,
  fishingSpotDetail,
  fishingDay,
  isNature,
  methodText,
  isIkejime,
  cleaningText,
  waterText,
  saveText,
  isVacuum,
  expenseText,
  shippingProvider,
  deliveryText,
  timingText,
  area,
  fixPrice,
  shippingExpense,
  shippingSize,
  isLandAfterFix,
  ikejimeText,
  prefecture,
  explanation,
  isCompany,
}) => {
  const {
    now,
    fishingDate,
    providerName,
    decisionPrice,
    deliveryPrice,
  } = useSection({
    fishingDay,
    shippingProvider,
    fixPrice,
    isLandAfterFix,
    area,
    prefecture,
    size,
    isCompany,
  });

  return (
    <div className="modal__sell-confirm__main-detail">
      <p className="modal__sell-confirm__main-description">{explanation}</p>

      <table>
        <thead />
        <tbody>
          <tr>
            <td className="modal__sell-confirm__main-td__label">出品者</td>
            <td>
              <Link to={paths.mypage.all}>{sellerName}</Link>
            </td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">出品日時</td>
            <td>{now}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">サイズ</td>
            <td>{size < 1 ? '-' : `${getLocaleStringLocales(size)} cm`}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">重量</td>
            <td>
              {weight < 1 ? '-' : `${getLocaleStringLocales(weight)} g`}
              <br />
              {weightAdditionalInfo}
            </td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">
              釣り場（産地）
            </td>
            <td>
              {spotText || '-'}
              <br />
              {fishingSpotDetail || '-'}
            </td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">
              釣り上げた日（水揚げ日）
            </td>
            <td>{fishingDate}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">分類</td>
            <td>{isNature ? '天然' : '養殖'}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">締め方</td>
            <td>
              {methodText || '-'}
              {isIkejime ? (
                <>
                  <br />
                  活〆
                </>
              ) : null}
            </td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">処理方法</td>
            <td>{cleaningText || '-'}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">
              処理に使用した水
            </td>
            <td>{waterText || '-'}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">
              締め処理を行ったタイミング
            </td>
            <td>{ikejimeText || '-'}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">保管方法</td>
            <td>
              {saveText || '-'}
              {isVacuum ? (
                <>
                  <br />
                  真空パック保管
                </>
              ) : null}
            </td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">
              配送料の負担
            </td>
            <td>
              {expenseText}
              {providerName ? (
                <>
                  <br />
                  {providerName}
                </>
              ) : null}
            </td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">配送方法</td>
            <td>{deliveryText || '-'}</td>
          </tr>

          {window.isAuthed && paymentMethod(expenseText) ? (
            <tr>
              <td className="modal__sell-confirm__main-td__label">
                クール便 <br />
                配送料目安
              </td>
              <td>
                {deliveryPrice ? (
                  <>
                    約{deliveryPrice}円 <br />
                    ※条件によって異なる場合がございます
                  </>
                ) : (
                  <>配送情報の取得に失敗したため、金額の表示に失敗しました。</>
                )}
              </td>
            </tr>
          ) : null}

          <tr>
            <td className="modal__sell-confirm__main-td__label">
              発送までの日数
            </td>
            <td>{timingText}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">
              発送元の地域
            </td>
            <td>{area}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">即決価格</td>
            <td>{decisionPrice}</td>
          </tr>

          <tr>
            <td className="modal__sell-confirm__main-td__label">
              配送サイズ区分
            </td>
            <td>{shippingExpense === 3 ? shippingSize : '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DetailSection;
