import axios from 'axios';

import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT } = getEnv();

export const postUserShipping = async (userId, b) => {
  try {
    const token = await Auth.getIdToken();
    await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .post(`${GO_ENDPOINT}/v1/shipping_info`, b);
  } catch (error) {
    throw new Error(error);
  }
};
