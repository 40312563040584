import axios from 'axios';

import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function getUserReviewPagination(pagination, sort) {
  try {
    const limit = pagination.limit;
    const current = pagination.current;
    const id = pagination.id;

    let token = '';
    let res = '';

    try {
      token = await Auth.getIdToken();
    } catch (error) {
      token = '';
    }

    const url = `${GO_ENDPOINT}/v1/rates_paging?limit=${limit}&page=${current}&sort=${sort}&user_id=${id}`;

    if (token) {
      res = await axios
        .create({
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .get(url);
    } else {
      res = await axios.get(url);
    }

    const result = {
      pages: res.data.pages,
      totalCount: Number(res.headers['x-total-count']),
      totalPages: Number(res.headers['x-total-pages']),
      current,
    };

    return result;
  } catch (err) {
    throw new Error(err);
  }
}

export default getUserReviewPagination;
