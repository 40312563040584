import $ from 'jquery';
import {
  isDetailToFishes,
  isDetailToFishesByLink,
  isFishesToDetail,
} from './utils';

export default function shouldUpdateScroll(
  prevRouterProps,
  { location, history }
) {
  if (isFishesToDetail(prevRouterProps, { location, history })) {
    // 魚詳細ページに遷移する時はScrollTopする。
    $(window.document).scrollTop(0);

    return false;
  } else if (isDetailToFishes(prevRouterProps, { location, history })) {
    // 魚詳細ページから出品一覧ページに遷移するときは手動でScrollする。
    if (typeof window.detailToSalesScrollY === 'number') {
      $(window.document).scrollTop(window.detailToSalesScrollY);
    }

    return false;
  } else if (isDetailToFishesByLink(prevRouterProps, { location, history })) {
    // 魚詳細ページから`Link`で出品一覧ページに遷移するときは手動でScrollする。
    const config = window.handleScrollForPageFishes.getValue();
    $(window.document).scrollTop(config.top);

    return false;
  }

  return (
    prevRouterProps && location.pathname !== prevRouterProps.location.pathname
  );
}
