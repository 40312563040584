import * as React from 'react';
import { Link } from 'react-router-dom';
import { CardElements } from '@/components/Common';
import enhance from './enhancer';
import { TitleBar } from '@/components/Common';
import { paths } from '@/config';

function RegistCredit() {
  return (
    <div className="app_credit-register flex">
      <TitleBar title="支払い方法変更(カード登録)" />
      <div className="available_card_notice">
        <p>ご利用可能な決済ブランド</p>
        <img src="https://fishsale.jp/assets/stripe-usable-card-brand.png" />
        <br />
        <p>クレジットカード、デビットカード、プリペイドカードにて、</p>
        <p>ほぼ全ての決済ブランドカードが使えます。</p>
        <p>残念ながら他決済は一切行えません。</p>
        <br />
        <Link to={paths.introduction.card}>セキュリティーコードについて</Link>
      </div>
      <div className="wrapper">
        <CardElements />
      </div>
    </div>
  );
}

export default enhance((props) => <RegistCredit {...props} />);
