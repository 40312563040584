import {
  paginationQueryBuilder,
  queryBuilder,
  isHttpStatusOK,
} from '@/apis/utils';
import { Auth } from '@/utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

export default async function getActivities(userId, pagination) {
  try {
    const token = await Auth.getIdToken();
    const query = queryBuilder(paginationQueryBuilder(pagination));

    const response = await fetch(
      `${GO_ENDPOINT}/v1/users/${userId}/activities_paging${query}&sort=-id`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (isHttpStatusOK(response.status)) {
      const json = await response.json();

      const totalCount = Number(response.headers.get('x-total-count'));
      // Paginationのロジックでは使わないので注意。
      const totalPages = Number(response.headers.get('x-total-pages'));

      return Object.assign(json, { totalCount, totalPages });
    }

    throw new Error(response);
  } catch (error) {
    throw new Error(error);
  }
}
