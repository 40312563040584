import { Auth } from '@/utils';
import { isHttpStatusOK } from './../utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

export default async function getActivitiesUnreadCounts(userId) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(
      `${GO_ENDPOINT}/v1/users/${userId}/activities/unread_count`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (isHttpStatusOK(response.status)) {
      return response.json();
    }

    throw new Error(response);
  } catch (error) {
    throw new Error(error);
  }
}
