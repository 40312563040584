import { Auth } from '@/utils';
import { isHttpStatusOK } from './../utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function deleteSale(saleId, lastUpdatedAt) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(
      `${GO_ENDPOINT}/v1/sales/${saleId}?last_updated_at=${lastUpdatedAt}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (isHttpStatusOK(response.status)) {
      return { message: 'success' };
    }

    throw new Error({ message: 'failed' });
  } catch (error) {
    console.error(error);
    return error;
  }
}

export default deleteSale;
