export default [
  {
    key: '1',
    value: 'all',
    text: '全ての魚',
  },
  {
    key: '2',
    value: 'canBidding',
    text: '入札可能な魚',
  },
  {
    key: '3',
    value: 'onDisplay',
    text: '未入札の魚',
  },
  {
    key: '4',
    value: 'bidding',
    text: 'オークション中の魚',
  },
  {
    key: '5',
    value: 'alreadyCompletedBidding',
    text: '落札済みの魚',
  },
  {
    key: '6',
    value: 'biddingComplete',
    text: 'オークションが終了した魚',
  },
];
