import { Auth, base } from '@/utils';

async function getNotReviewedItem() {
  try {
    const token = await Auth.getIdToken();
    const response = await base.get('/v1/unreviewed', {
      headers: { Authorization: `Bearer ${token}` },
    });
    const result = response.data;
    return result;
  } catch (error) {
    throw new Error(error);
  }
}

export default getNotReviewedItem;
