import { base, Auth } from '@/utils';

export const postFollow = async (userId, followId) => {
  const token = await Auth.getIdToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const url = `/v1/users/${userId}/follows`;

    const response = await base.post(
      url,
      { follow_id: followId },
      { headers: headers }
    );

    return response;
  } catch (error) {
    throw new Error(error);
  }
};
