import React from 'react';
import SideNav from '../SideNav';

type Props = {
  handleSearch: (query: any) => void;
  handleToggle: (open: boolean) => void;
  isOpen: boolean;
  isCompany?: boolean;
  location?: any;
};

const FishSideNav: React.FC<Props> = ({
  handleSearch,
  handleToggle,
  isOpen,
  isCompany,
  location,
}) => (
  <>
    <SideNav
      handleSearch={handleSearch}
      handleToggle={handleToggle}
      isOpen={isOpen}
      isCompany={isCompany}
      location={location}
    />

    {isOpen ? (
      <div
        className="b__sidenav__overlay"
        onClick={() => {
          handleToggle(false);
        }}
        role="presentation"
      />
    ) : null}
  </>
);

export default FishSideNav;
