import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Dimmer, Header, Loader } from 'semantic-ui-react';
import { paths } from '@/config';
import enhance from './enhancer';

function StripeCallback(props) {
  return (
    <div className="app_stripe_complete flex">
      <Dimmer page active={props.isLoading}>
        <Loader active={props.isLoading} />
      </Dimmer>
      <div
        className={classNames({
          wrapper: true,
          app_hidden: props.isLoading,
        })}
      >
        <div className="w__contents flex">
          <Header as="h1" className="title_text">
            口座登録が完了しました！
          </Header>
          <div className="flex">
            <p className="container_text">
              売上げ受取口座のご登録ありがとうございます。
            </p>
          </div>
          <Link to={paths.sell} className="state_loggined flex">
            <Button type="button" className="submit_login">
              <div className="grid">
                <span className="content flex">魚を出品する</span>
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <StripeCallback {...props} />);
