import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';

const FieldIkejimeWaterRadio = (props) => {
  const { input, meta } = props;

  return (
    <div className="ikejime-water-radio-wrapper">
      {props.items.map((item, index) => {
        return (
          <Checkbox
            {...props}
            key={index}
            radio
            value={item.value}
            label={item.text}
            onChange={() => input.onChange(item.value)}
            checked={input.value === item.value}
          />
        );
      })}

      <br />
      {meta.touched && meta.error && (
        <a id="error">
          <span className="redux_form error">{meta.error}</span>
        </a>
      )}
    </div>
  );
};

export default FieldIkejimeWaterRadio;
