export default [
  {
    key: '1',
    value: 'なし',
    text: 'なし',
  },
  {
    key: '2',
    value: '警察',
    text: '警察',
  },
  {
    key: '3',
    value: '海上保安庁',
    text: '海上保安庁',
  },
  {
    key: '4',
    value: '保健所',
    text: '保健所',
  },
  {
    key: '5',
    value: '消費者庁',
    text: '消費者庁',
  },
  {
    key: '6',
    value: ' 環境省',
    text: '環境省',
  },
  {
    key: '7',
    value: '地方環境事務所',
    text: '地方環境事務所',
  },
  {
    key: '8',
    value: 'その他',
    text: 'その他',
  },
];
