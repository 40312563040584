import * as React from 'react';
import Card from '@/components/Common/Card';

function UnratedSalesList(props) {
  const { data } = props;

  const INVALID_VALUE = 2147483647;

  return (
    <div id="observable_fishes_view" className="app_sales_list flex">
      {data.map((item) => {
        return (
          <Card
            key={item.id}
            id={item.id}
            fishKind={item.fishKind || ''}
            imageUrl={item.imageUrl || item.imageURL}
            sellerName={item.sellerName}
            sellerCompany={item.sellerCompany}
            fixPrice={INVALID_VALUE === item.fixPrice ? '-' : item.fixPrice}
            isCompany={item.isCompany}
            displayPrice={item.displayPrice}
            bidCount={item.bidCount}
            fixed={item.fixed}
            closeBidAtTemp={item.closeBidAtTemp}
            fishingSpot={item.fishingSpot}
            weight={item.weight}
            isNature={item.isNature}
            shippingExpense={item.shippingExpense}
            bid={item.bid}
            closed={item.closed}
            closedReason={item.closedReason}
            commentAdd={item.commentAdd}
            imageAdd={item.imageAdd}
          />
        );
      })}
    </div>
  );
}
export default UnratedSalesList;
