import * as React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '@/config';
import { RedCircleBadge } from '../RedCircleBadge';
import enhance from './enhancer';

function NavBar(props) {
  return window.isAuthed ? (
    <div className="app__navbar">
      <Link to={paths.sell} className="link_button">
        <span className="is__pc">出品する</span>
        <span className="is__mobile">
          <span>出品</span>
          <span>する</span>
        </span>
      </Link>

      <a
        href={`${paths.fishes.all}`}
        className="link_button"
        onClick={props.handleResetHeaderText}
      >
        <span className="is__pc">出品魚一覧</span>
        <span className="is__mobile">
          <span>出品魚</span>
          <span>一覧</span>
        </span>
      </a>

      <Link to={paths.mypage.admin} className="link_button">
        <span>マイページ</span>
      </Link>
      <Link
        to={`${paths.mypage.notification}?category=g&page=1`}
        className="link_button notification"
      >
        <RedCircleBadge />
        <span>通知</span>
      </Link>
    </div>
  ) : (
    <div className="app__navbar">
      <a
        href={paths.fishes.all}
        className="link_button before"
        onClick={props.handleResetHeaderText}
      >
        <span className="is__pc">出品魚一覧</span>
        <span className="is__mobile">
          <span>出品魚</span>
          <span>一覧</span>
        </span>
      </a>

      <Link to={paths.before.signin} className="signin">
        ログイン
      </Link>

      <Link to={paths.before.signup} className="signup">
        新規会員登録
      </Link>
    </div>
  );
}

export default enhance((props) => <NavBar {...props} />);
