import React from 'react';

const ERROR_MESSAGE = `カード決済に失敗しました。入札のカードが不正カードの可能性があります。\nマイページの支払い方法変更より正しいカード情報を登録して下さい。`;

function ErrorModal(props) {
  return (
    <div className="error-modal">
      <h3>決済エラー詳細</h3>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {ERROR_MESSAGE === props.errorMessage ? (
          <div>
            <p>
              カード決済で問題が発生しました。出品者または、入札者のStripeアカウントまたは、カード情報に不備がある可能性があります。
            </p>
            <p>
              出品者様は
              <a
                href="https://dashboard.stripe.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripeアカウント
              </a>
              にログインして問題が発生していないかご確認ください。入札者様はカード限度額や残高に問題がないかご確認ください。解決しない場合は、
              <a
                href="https://fishsale.jp/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら(お問い合わせフォーム)
              </a>
              からお問い合わせ下さい。
            </p>
          </div>
        ) : (
          <div>
            {props.errorMessage.split('\n').map((message) => (
              <p key={message}>{message}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ErrorModal;
