import * as React from 'react';
import { Link } from 'react-router-dom';
import enhance from './enhancer';
import { getLocaleStringLocales } from '@/utils';
import { paymentMethod, isSeller, getDeliveryPrices } from '../../../../utils';
import { paths } from '@/config';
import { replaceTofu } from '@/utils/string';

const INVALID_DATE = 'Invalid date';

function SaleInfoList(props) {
  const {
    sales: {
      item: {
        sellerUserId,
        sellerName,
        postedAt,
        size,
        weight,
        isNature,
        isIkejime,
        weightAdditionalInfo,
        fishingSpot,
        fishingSpotDetail,
        methodText,
        waterText,
        expenseText,
        timingText,
        area,
        fixPrice,
        deliveryText,
        ikejimeTimingText,
        fishingDay,
        saveText,
        isVacuum,
        cleaningText,
        shippingExpense,
        shippingSize,
        shippingProvider,
        isLandAfterFix,
      },
    },
    users: {
      mydata: { id, prefecture },
    },
  } = props;

  let fishingDate = '';
  if (fishingDay === INVALID_DATE) {
    fishingDate = '-';
  } else {
    fishingDate = fishingDay;
  }

  // 落札後水揚げにチェックが付いていれば、落札後水揚げを表示する
  if (isLandAfterFix) {
    fishingDate = '落札後水揚げ';
  }

  let shippingProviderName = '';

  if (shippingProvider === 1) {
    shippingProviderName = '佐川急便';
  } else if (shippingProvider === 2) {
    shippingProviderName = 'ヤマト運輸';
  } else if (shippingProvider === 3) {
    shippingProviderName = '日本郵便 ';
  }

  return (
    <dl className="a__saleinfo_list">
      <dt>出品者</dt>
      <dd className="seller_name">
        {id === sellerUserId ? (
          <Link to={paths.mypage.all}>{replaceTofu(sellerName)}</Link>
        ) : (
          <Link to={`/users/${sellerUserId}`}>{replaceTofu(sellerName)}</Link>
        )}
      </dd>

      <dt>出品日時</dt>
      <dd>{postedAt}</dd>

      <dt>サイズ</dt>
      <dd className="size">
        <p>{Number(size) < 1 ? '-' : `${getLocaleStringLocales(size)} cm`}</p>
      </dd>

      <dt>重量</dt>
      <dd className="weight">
        <p>
          {Number(weight) < 1 ? '-' : `${getLocaleStringLocales(weight)} g`}
          <br />
          {weightAdditionalInfo}
        </p>
      </dd>

      <dt>釣り場（産地）</dt>
      <dd>
        <p>
          {fishingSpot || '-'}
          <br />
          {fishingSpotDetail || ''}
        </p>
      </dd>

      <dt>釣り上げた日（水揚げ日）</dt>
      <dd>{fishingDate}</dd>

      <dt>分類</dt>
      <dd>{isNature ? '天然' : '養殖'}</dd>

      <dt>締め方</dt>
      <dd>
        {methodText || '-'}
        {isIkejime ? (
          <>
            <br />
            活〆
          </>
        ) : (
          ''
        )}
      </dd>

      <dt>処理方法</dt>
      <dd>{cleaningText || '-'}</dd>

      <dt>処理に使用した水</dt>
      <dd>{waterText || '-'}</dd>

      <dt>締め処理を行ったタイミング</dt>
      <dd>{ikejimeTimingText || '-'}</dd>

      <dt className={isVacuum ? 'w__save' : null}>保管方法</dt>
      <dd>
        <p>
          {saveText || '-'}
          {isVacuum ? (
            <>
              <br />
              真空パック保管
            </>
          ) : (
            ''
          )}
        </p>
      </dd>

      <dt>配送料の負担</dt>
      <dd>
        {expenseText}
        {shippingProviderName ? (
          <>
            <br />
            {shippingProviderName}
          </>
        ) : null}
      </dd>

      <dt>配送方法</dt>
      <dd>{deliveryText || '-'}</dd>

      {window.isAuthed ? (
        <>
          {paymentMethod(expenseText) && !isSeller(sellerUserId, id) ? (
            <>
              <dt>
                クール便 <br />
                配送料目安
              </dt>
              {getDeliveryPrices(area, prefecture, size) ? (
                <dd>
                  約{getDeliveryPrices(area, prefecture, size)}円 <br />
                  ※条件によって異なる場合がございます
                </dd>
              ) : (
                <dd>
                  配送情報の取得に失敗したため、金額の表示に失敗しました。
                </dd>
              )}
            </>
          ) : null}
        </>
      ) : null}

      <dt>発送までの日数</dt>
      <dd>{timingText}</dd>

      <dt>発送元の地域</dt>
      <dd>{area}</dd>

      <dt>即決価格</dt>
      <dd>
        {fixPrice >= 2147483647 ? '-' : `¥${getLocaleStringLocales(fixPrice)}`}
      </dd>
      <dt>配送サイズ区分</dt>
      <dd>{shippingExpense === 3 ? shippingSize : '-'}</dd>
    </dl>
  );
}

export default enhance((props) => <SaleInfoList {...props} />);
