import { isHttpStatusOK, parseFailed } from '@/apis/utils';
import { parseRate } from './utils';
import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function putUserRate(userId) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(`${GO_ENDPOINT}/v1/users/${userId}/rate`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();

    if (isHttpStatusOK(response.status)) {
      const nextResponse = responseJson.map((responseJson) => {
        return parseRate(responseJson);
      });

      return nextResponse;
    }

    throw parseFailed(responseJson.errors[0]);
  } catch (error) {
    return error;
  }
}

export default putUserRate;
