import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose, setDisplayName, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';

import { salesAction } from '@/actions';
import { paths } from '@/config';

const mapStateToProps = (state) => ({
  form: state.form.toJS(),
  app: state.app.toJS(),
  sales: state.sales.toJS(),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...salesAction, push }, dispatch);

const enhance = compose(
  setDisplayName('FishSearchForm'),
  reduxForm({
    form: 'fishSearchForm',
    initialValues: {
      statusCategory: 'all',
      area: '0',
      closed: 0,
      fromDay: '1',
      // sorts: '-biddable-fixed-created_at',
      sorts: '-biddable-fixed_at+close_bid_at',
    },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    updateUnregisteredFields: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'updateLoading', false),
  withHandlers({
    handleSearch: (props) => async (values) => {
      const { activeTab, handleSort, updateLoading, history } = props;

      const status = values.get('statusCategory');
      const area = values.get('area');
      const sort = values.get('sorts');

      try {
        updateLoading(true);
        if (window.location.pathname === paths.landing) {
          history.push(
            `${paths.fishes.all}?page=1&status=${status}&area=${area}&sort=${sort}`
          );
        } else {
          switch (activeTab) {
            case 0: {
              handleSort({ status, area, sort });
              break;
            }
            case 1: {
              handleSort({ status, area, sort });
              break;
            }
            case 2: {
              handleSort({ status, area, sort });
              break;
            }
          }
        }
      } catch (error) {
        // FIXME
      } finally {
        updateLoading(false);
      }
    },
  })
);

export default enhance;
