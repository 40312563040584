import { Auth } from '@/utils';
import axios from 'axios';
import { parseSale } from './utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

const getUsersCompany = async (page, sort) => {
  let headers;

  try {
    const token = await Auth.getIdToken();
    headers = { Authorization: `Bearer ${token}` };
  } catch (error) {
    headers = {};
  }

  try {
    const fetchData = await axios.get(
      `${GO_ENDPOINT}/v1/users_company?limit=5&page=${page}${
        sort ? `&sort=${sort}` : ''
      }`,
      {
        headers,
      }
    );

    fetchData.data.map((company, cid) => {
      company.sales.map((sale, sid) => {
        fetchData.data[cid].sales[sid] = parseSale(sale);
      });
    });

    return {
      data: fetchData.data,
      totalCount: fetchData.headers['x-total-count'],
      totalPages: fetchData.headers['x-total-pages'],
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default getUsersCompany;
