/* eslint-disable no-useless-catch */
import { Auth } from '@/utils';
import { parseSaleBid } from './utils';
import { getEnv } from '@/utils';
import isHttpStatusOK from '@/apis/utils/isHttpStatusOK';
import parseFailed from '@/apis/utils/parseFailed';

const { GO_ENDPOINT } = getEnv();

async function postSaleBid(saleID, data) {
  try {
    const token = await Auth.getIdToken();
    const { body, price, shipping_info_id } = data;

    const response = await fetch(`${GO_ENDPOINT}/v1/sales/${saleID}/bids`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ body, price, shipping_info_id }),
    });
    const responseJson = await response.json();

    if (isHttpStatusOK(response.status)) {
      return parseSaleBid(responseJson);
    }

    throw new Error(parseFailed(responseJson.errors[0]).message);
  } catch (error) {
    throw error;
  }
}

export default postSaleBid;
