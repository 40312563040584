import axios from 'axios';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

const resouces = 'news';

export const getNews = async () => {
  try {
    const { data } = await axios.get(`${GO_ENDPOINT}/v1/${resouces}`);

    return data;
  } catch (error) {
    console.error(error);
  }
};
