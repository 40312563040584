import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';

function FieldRedListAgreement(props) {
  const { meta, input } = props;

  return (
    <>
      <Checkbox
        {...props}
        onChange={(e, { checked }) => input.onChange(checked)}
        checked={!!input.value}
        id="sell-red-list-checkbox"
      />
      <label htmlFor="sell-red-list-checkbox" className="compliance_label">
        絶滅危惧種に該当しない (絶滅危惧種リストは
        <a
          href="http://www.env.go.jp/press/files/jp/114457.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        )
      </label>
      <br />
      {meta.touched && meta.error && (
        <a id="error">
          <span className="redux_form error">{meta.error}</span>
        </a>
      )}
    </>
  );
}

export default FieldRedListAgreement;
