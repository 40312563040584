import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';

function FiealdToxicFishAgreement(props) {
  const { meta, input } = props;
  return (
    <>
      <Checkbox
        {...props}
        onChange={(e, { checked }) => input.onChange(checked)}
        checked={!!input.value}
        id="toxic-fish-agreement-checkbox"
      />
      <label
        htmlFor="toxic-fish-agreement-checkbox"
        className="compliance_label"
      >
        有毒魚に該当しない (有毒魚リストは
        <a
          href="http://www.fukushihoken.metro.tokyo.jp/itiba/suisanbutu/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        )
      </label>
      <br />
      {meta.touched && meta.error && (
        <a id="error">
          <span className="redux_form error">{meta.error}</span>
        </a>
      )}
    </>
  );
}

export default FiealdToxicFishAgreement;
