import { paths } from '@/config';
import React from 'react';
import './style.scss';

const index = () => {
  return (
    <div className="container">
      <div className="container__inner">
        <h1>Stripeに関してよくある質問</h1>
        <div className="faq">
          {/* 追加情報提出を求められた場合の項目をどのように入力すればいいですか？ */}
          <div className="faq__item">
            <p className="faq__heading">
              追加情報提出を求められた場合の項目をどのように入力すればいいですか？
            </p>
            <div className="faq__answer">
              <p className="title">
                ①「開業届、もしくは確定申告書」をお持ちでない理由の入力方法
              </p>
              <p>
                個人の出品者様が情報ご提示できない場合、ご提示できない理由（個人出品のため開業届・確定申告書を持ち合わせていない）などを記載してください
              </p>

              <p className="title">
                ②「ビジネスに関する補足書類（ライセンスなど）」欄の入力・アップロード方法
              </p>
              <p>
                ①でご回答いただいた内容に順じて、個人事業主では無い個人出品者である旨などを記載していただき提出してください
              </p>

              <p className="title">
                ③「オプションのビジネス情報入力欄」の入力方法
              </p>
              <p>
                ①でご回答いただいた内容に順じて、個人事業主では無い個人出品者である旨などを記載していただき提出してください
              </p>

              <p className="title">
                ④ 決済を受け付けるために使用する Stripe 製品を教えてください。
              </p>
              <p>Connect</p>

              <p className="title">⑤ Stripe を介して何を販売していますか？</p>
              <p>FishSaleに出品する予定の商品（鮮魚 など）</p>

              <p className="title">
                ⑥ このアカウントに関連する、主要な顧客向けウェブサイトの URL
                をご提示ください。
              </p>
              <p>
                <a href={paths.introduction.howto_sales_account}>
                  Stripeの登録方法について
                </a>
                <b>STEP6</b>で説明しているURL（出品者様のマイページ）
              </p>

              <p className="title">
                ⑦ 特定商取引法に基づくビジネス情報を記載したページの URL
                をご提示ください。
              </p>
              <p>https://about.freshspeed.net/law.html</p>

              <p className="title">
                ⑧ ・ビジネスの登録書類のコピーをお持ちですか？
                ・お客様のビジネスに該当する提供可能な補足書類をお持ちですか？
              </p>
              <p>
                ① でご回答いただいた内容に順じて、
                <b>
                  個人事業主では無い個人出品者である旨などを記載していただき
                </b>
                提出してください
              </p>

              <br />
              <p>
                ご回答いただき次第、Stripeにて内容を確認、必要であれば追加情報提出ご依頼などのご連絡がございます。
              </p>
              <p>
                また、
                <b>
                  これら問い合わせに対してStripeに質問を行う場合は「Stripe審査部よりお送りしているメールに関してはメールに記載されている方法（メール内リンクより回答・メールに直接返信など）にてご回答」していただく必要がございます。
                </b>
                <br />
                弊社にお問合せいただいても、審査情報保護の観点からStripeよりご回答がいただけないためあらかじめご了承ください。
              </p>
            </div>
          </div>
          {/* セキュリティ・チェックリストに基づく対策措置状況申告書 */}
          <div className="faq__item">
            <p className="faq__heading">
              セキュリティ・チェックリストに基づく対策措置状況申告書を求められた場合の項目をどのように入力すればいいですか？
            </p>
            <div className="faq__answer">
              <p className="title">
                導入する方法について詳細をお知らせください
              </p>
              <p>
                顧客はどのように決済を行いますか？
                <br />
                =&gt; その他（Payments、Connect）
                <br />
                <br />
                オンラインサイト上に商品・サービスを掲載していますか？
                <br />
                =&gt; はい
              </p>
              <p className="title">
                1. 管理画面のアクセス制限と管理者のID・PW管理
              </p>
              <p>
                管理者のアクセス可能なIPアドレスを制限できない場合は管理画面にベーシック認証等のアクセス制限を設ける。
                <br />
                =&gt; はい
                <br />
                <br />
                取得されたアカウントを不正使用されないように二段解認証または二要素認証を採用する
                <br />
                =&gt; いいえ
                <br />
                <br />
                管理画面のログインフォームでは、アカウントロック機能を有効にし、10回以下のログイン失敗でアカウントをロックする。
                <br />
                =&gt; いいえ
              </p>
              <p className="title">
                2. データディレクトリの露見に伴う設定不備への対策
              </p>
              <p>
                公開ディレクトリには重要なファイルを配置しない。
                <br />
                =&gt; はい
                <br />
                <br />
                WebサーバやWebアプリケーションによりアップロード可能な拡張子やファイルを制限する等の設定を行う。
                <br />
                =&gt; はい
              </p>
              <p className="title">3. Webアプリケーションの脆弱性対策</p>
              <p>
                脆弱性診断またはペネトレーションテストを定期的に実施し、必要な修正対応を行う。
                <br />
                =&gt; いいえ
                <br />
                <br />
                SQLインジェクションの脆弱性やクロスサイトスクリプティングの脆弱性対策を行う
                <br />
                =&gt; はい
                <br />
                <br />
                Webアプリケーションを開発またはカスタマイズされている場合には、セキュアコーディング済みであるか、ソースコードレビューを行い確認する。その際は、入力フォームの入力値チェックも行う。
                <br />
                =&gt; はい
              </p>
              <p className="title">
                4. マルウェア対策としてのウイルス対策ソフトの導入、運用
              </p>
              <p>
                マルウェア検知 /
                除去などの対策としてウイルス対策ソフトを導入して、シグネチャーの更新や定期的なフルスキャンを行う。
                <br />
                =&gt; いいえ
              </p>
              <p className="title">
                5. 悪質な有効性確認、クレジットマスターへの対策
              </p>
              <p>
                悪質な有効性確認、クレジットマスターに対して、セキュリティ。チェックリストに記載の対策を１つ以上実施している。
                <br />
                =&gt; はい
              </p>
              <p className="title">6. 不正ログイン対策</p>
              <p>
                会員登録時
                <br />
                =&gt; 不審なIPアドレスからのアクセス制限
                <br />
                <br />
                ログイン認証時
                <br />
                =&gt; 不審なIPアドレスからのアクセス制限
                <br />
                <br />
                属性変更時
                <br />
                =&gt; 不審なIPアドレスからのアクセス制限
                <br />
                <br />
                <b>委託先情報</b>
                <br />
                <br />
                貴社に代わってどなたがセキュリティ対策を実行しますか？
                <br />
                =&gt; 委託先企業
                <br />
                <br />
                委託先企業名
                <br />
                =&gt; Fresh Speed株式会社
                <br />
                <br />
                ASPカート事業者名
                <br />
                =&gt; FishSale
                <br />
                <br />
                PCI DSS準拠の審査を行ったQSA(Qualified Security Assessor)
                <br />
                =&gt; なし
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
