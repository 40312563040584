import * as React from 'react';
import { Icon, Image } from 'semantic-ui-react';
import { assets } from '@/config';

function Page2() {
  return (
    <div className="app__introduction_account">
      <div className="b__how_to">
        <div className="w__header_label">
          <h3>口座登録方法について</h3>
        </div>
        <div className="w__row w__first_row">
          <div className="w__image">
            <Image src={assets.account_1} />
          </div>
          <div className="w__contents">
            <div className="w__title_wrapper">
              <h3 className="w__title_number">1.</h3>
              <h3 className="w__title">
                マイページメニューの「売上げ受取方法」を押してください。
              </h3>
            </div>
            <span>
              マイページのメニューにある「売上げ受取方法」を押してください。
            </span>
          </div>
        </div>
        <Icon color="yellow" name="caret down" size="huge" />
        <div className="w__row w__second_row">
          <div className="w__image">
            <Image src={assets.account_2} />
          </div>
          <div className="w__contents">
            <div className="w__title_wrapper">
              <h3 className="w__title_number">2.</h3>
              <h3 className="w__title">
                「売上げ受取口座を登録する」を押してください。
              </h3>
            </div>
            <span>
              「売上げ受取方法」の「売上げ受取口座を登録する」を押してください。
            </span>
          </div>
        </div>
        <Icon color="yellow" name="caret down" size="huge" />
        <div className="w__row">
          <div className="w__image">
            <Image src={assets.account_3} />
          </div>
          <div className="w__contents">
            <div className="w__title_wrapper">
              <h3 className="w__title_number">3.</h3>
              <h3 className="w__title">
                口座登録ページに変わるので、必須事項を入力してください。
              </h3>
            </div>
            <span>
              Stripeという外部サイトの口座登録ページに移動するので、こちらで必須事項を入力してください。
            </span>
          </div>
        </div>
        <Icon color="yellow" name="caret down" size="huge" />
        <div className="w__row bottom">
          <div className="w__image">
            <Image src={assets.account_4} />
          </div>
          <div className="w__contents">
            <div className="w__title_wrapper">
              <h3 className="w__title_number">4.</h3>
              <h3 className="w__title">
                ページ下部の青いボタンを押したら、口座登録完了です。
              </h3>
            </div>
            <span>
              必須事項を入力し終わったら、ページ下部の青いボタンを押してください。これで口座登録は完了です。
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page2;
