import * as React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { assets, paths } from '@/config';
import enhance from './enhance';

function NotFound(props) {
  return (
    <div className="app__notfound">
      <div className="w__content">
        <div className="b__message">
          <div>
            <span className="w__message">
              ご指定のページが見つかりませんでした。URLが間違っているか、ページが削除された可能性があります。
            </span>
          </div>
          <div className="turn_landing flex">
            <span className="hidden_less_sm">
              <Link to="#" onClick={() => props.goBack()}>
                前のページに戻る
              </Link>
              <span>・</span>
            </span>
            <Link to={paths.landing}>Fish Saleトップへ</Link>
          </div>
        </div>
        <Image src={assets.notfound_logo} />
      </div>
    </div>
  );
}

export default enhance((props) => <NotFound {...props} />);
