import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { externalPaths } from '@/config/paths';

function FieldAgreement(props) {
  const { meta, input } = props;

  return (
    <div className="a__term-agreement">
      <Checkbox
        {...props}
        onChange={(e, { checked }) => input.onChange(checked)}
        checked={!!input.value}
        id="agreement-checkbox"
      />
      <span>
        <label htmlFor="agreement-checkbox" style={{ cursor: 'pointer' }}>
          <a
            href={externalPaths.terms}
            rel="noopener noreferrer"
            target="_blank"
          >
            利用規約
          </a>
          に同意する
        </label>
      </span>
      <br />
      {meta.touched && meta.error && (
        <span className="redux_form error">{meta.error}</span>
      )}
    </div>
  );
}

export default FieldAgreement;
