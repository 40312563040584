import axios from 'axios';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

export const getShippingCosts = async (userId) => {
  const { data } = await axios.get(
    `${GO_ENDPOINT}/v1/shipping_costs/${userId}`
  );

  return data;
};
