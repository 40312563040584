import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';

function FieldRareSpeciesAgreement(props) {
  const { meta, input } = props;

  return (
    <>
      <Checkbox
        {...props}
        onChange={(e, { checked }) => input.onChange(checked)}
        checked={!!input.value}
        id="sell-rare-spacies-checkbox"
      />
      <label htmlFor="sell-rare-spacies-checkbox" className="compliance_label">
        国内希少種に該当しない (国内希少種リストは
        <a
          href="https://www.env.go.jp/nature/kisho/domestic/list.html#gyorui"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        )
      </label>
      <br />
      {meta.touched && meta.error && (
        <a id="error">
          <span className="redux_form error">{meta.error}</span>
        </a>
      )}
    </>
  );
}

export default FieldRareSpeciesAgreement;
