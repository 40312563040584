import * as React from 'react';
import { Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Field } from 'redux-form/immutable';
import { FieldLabelLinkCheckbox } from '../../Form';
import Pagination from '@/migration/pagination';

import enhance from './enhance';
import * as utils from '../../../utils';
import { constants } from '../../../constants';
import { paths } from '@/config';

import * as Styles from './styles';

function NotificationList(props) {
  return (
    <div
      className={classNames('mypage_notification', {
        'zero-message': props.users.activities.pages.active.length === 0,
      })}
    >
      {props.isLoading || props.isListLoading ? (
        <Loader active size="tiny" className="tiny-loader" inline="centered" />
      ) : (
        <div className="w__notification-list">
          {props.users.activities.pages.active.length === 0 ? (
            <div>通知は来ていません</div>
          ) : (
            <div
              className={classNames('notifications', {
                loading: props.isLoading || props.isListLoading,
              })}
            >
              <form>
                <div
                  style={Styles.allcheckboxLabel}
                  className="info w__all_check"
                >
                  <Field
                    name="AllIsReadForm"
                    id="AllIsReadForm"
                    component={FieldLabelLinkCheckbox}
                    onChange={(event, field) => {
                      if (field === true) {
                        props.users.activities.pages.active.map((row) => {
                          props.change(row.id, true);
                        });
                      } else {
                        props.users.activities.pages.active.map((row) => {
                          props.change(row.id, false);
                        });
                      }
                    }}
                  >
                    <span className="w__all_check_label">全て選択する</span>
                  </Field>
                </div>
                {props.users.activities.pages.active.map((row) => {
                  return (
                    <div
                      className={classNames('info', { read: row.isRead })}
                      key={row.id}
                    >
                      <div style={Styles.flexField}>
                        <Field
                          name={row.id}
                          id={row.id}
                          component={FieldLabelLinkCheckbox}
                          changePage={props.onChangeCheck}
                          // eslint-disable-next-line
                          children={
                            <div
                              className="info_datetime"
                              style={Styles.checkboxLabel}
                            >
                              {row.datetime}
                              {row.isRead ? null : (
                                <span className="info_label">NEW</span>
                              )}
                            </div>
                          }
                        />
                      </div>
                      <span>
                        <div className="info_title">
                          {row.code ===
                          constants.ACTIVITY_CODES.ADMIN_CLOSED_SALE ? (
                            <div className="info">
                              {utils.activities.getActivityMsg(
                                row.code,
                                row.context.saleTitle,
                                row.context.userName
                              )}
                            </div>
                          ) : (
                            <Link
                              to={`${paths.notificationRedirect}?code=${row.code}&saleId=${row.context.saleId}&uid=${row.context.userId}&targetUrl=${row.url}`}
                              target="_blank"
                              className="info_link"
                              onClick={() => {
                                props.readNotification(row.id);
                              }}
                            >
                              {utils.activities.getActivityMsg(
                                row.code,
                                row.context.saleTitle,
                                row.context.userName
                              )}
                            </Link>
                          )}

                          {row.code ===
                            constants.ACTIVITY_CODES.SELLER_SALE_FIXED && (
                            <span>
                              (詳しくは
                              <a
                                href={
                                  constants.URL
                                    .TELL_ME_ABOUT_DISPLAY_EXAMPLE_OF_FISHERY_PRODUCT
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="info_link"
                              >
                                こちら
                              </a>
                              )
                            </span>
                          )}
                        </div>
                      </span>
                    </div>
                  );
                })}
              </form>
              <div
                className={classNames('w__pagination', {
                  disabled: props.isPaginationDisabled,
                })}
              >
                <Pagination
                  totalCount={props.users.activities.totalCount}
                  current={props.users.activities.current}
                  pageSize={8}
                  changePage={props.onChangePage}
                  disabled={props.users.activities.isPaginationDisabled}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default enhance((props) => <NotificationList {...props} />);
