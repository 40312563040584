import { Auth } from '@/utils';
import axios from 'axios';
import qs from 'qs';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

export const getSalesPagination = async (params) => {
  let headers;

  try {
    const token = await Auth.getIdToken();
    headers = { Authorization: `Bearer ${token}` };
  } catch (error) {
    headers = {};
  }

  try {
    const iAxios = axios.create({
      headers,
    });

    iAxios.interceptors.request.use((config) => {
      if (config.method === 'get') {
        config.paramsSerializer = (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        };
        return config;
      }
    });

    const fetchData = await iAxios.get(`${GO_ENDPOINT}/v1/sales_paging`, {
      params,
    });

    return {
      ...fetchData.data,
      totalCount: fetchData.headers['x-total-count'],
      totalPages: fetchData.headers['x-total-pages'],
    };
  } catch (error) {
    throw new Error(error);
  }
};
