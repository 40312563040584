/** 魚詳細ページ */
import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Header, Button } from 'semantic-ui-react';
import Modal from '@/migration/modal';
import $ from 'jquery';

import { CLOSED_REASON, constants } from './../../../constants';
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
  ImageSlider,
} from '@/components/Common';
import { getLocaleStringLocales } from '@/utils';
import { NotFound } from '../NotFound';
import { BidButton } from './BidButton';
import { BidHistory } from './BidHistory';
import enhance from './enhancer';
import { SaleInfoList } from './SaleInfoList';
import { SnsIconRow } from './SnsIconRow';
import { TradeComments } from './TradeComments';
import { TradeCommentsBefore } from './TradeCommentsBefore';
import { ReportModal } from './ReportModal';
import ErrorModal from './ErrorModal';
import { useState } from 'react';
import FareTable from './FareTable';
import { paths } from '@/config';

import { isValidShippingData } from './isValidShippingData';

const CHARGE_FAILED = 5;
const AN_HOUR = 60;

function Sale(props) {
  const {
    sales: {
      item: {
        fishKind,
        explanation,
        boughtUserId,
        expenseText,
        closeBidAt,
        closeBidAtTemp,
        displayPrice,
        fixed,
        sellerUserId,
        bid,
        closedReason,
      },
    },
    displayBidsErrorMessage,
    displayBidsError,
    users,
    isMountLoading,
    setReportModal,
    openReportModal,
    sales,
    prefecture,
    shippingCost,
    shippingCosts,
    images,
    setImages,
  } = props;
  const [showFareTable, setShowFareTable] = useState(false);
  const handleClick = () => {
    setShowFareTable(true);
  };
  const handleCloseModal = () => {
    setShowFareTable(false);
  };
  const {
    mydata: { id },
  } = users;

  const date = new Date();
  const cdate = new Date(closeBidAtTemp);

  let diff = (cdate.getTime() - date.getTime()) / constants.MINUTE_MILLISECOND;
  diff = Math.round(diff);

  if (window.isFishData) {
    return (
      <div className="app_sale">
        <div
          className={classNames({
            grid_sale: true,
            app_mounting: isMountLoading,
          })}
        >
          <div className="grid_sale_detail tablet_view">
            <div className="grid_sale_view">
              <Header as="h2" className="sale_main_title">
                {fishKind}
              </Header>
              <div className="w__sale_image">
                {bid && !fixed && <div className="bidding">オークション中</div>}
                {fixed && <div className="rakusatu">落札済み</div>}
                {closedReason === CLOSED_REASON.EXPIRED && (
                  <div className="expired">期限切れ</div>
                )}
                {closedReason === CLOSED_REASON.CANCELED && (
                  <div className="expired">取り下げ</div>
                )}
                {closedReason === CLOSED_REASON.FAILED_CHARGING && (
                  <div className="failed-chaging">決済エラー</div>
                )}
                <ImageSlider images={props.sales.item.images} />

                <Button
                  className="preview"
                  onClick={() => {
                    // 複数枚出品写真がある場合、それぞれ表示できるように
                    // 一枚しか写真がない場合でも、data-indexは存在するため表示可能
                    const imageIndex = $(
                      '.slick-slide.slick-active.slick-current'
                    ).attr('data-index');
                    window.open(
                      `${props.sales.item.images[imageIndex]}`,
                      '_blank'
                    );
                  }}
                >
                  プレビュー
                </Button>

                {props.sales.item.closedReason === CHARGE_FAILED &&
                  props.sales.item.stripeErrorMessage && (
                    <div>
                      <p
                        onClick={() => props.setIsErrorModal(true)}
                        role="presentation"
                        className="error-paragraph"
                      >
                        決済エラーの詳細を表示する
                      </p>
                    </div>
                  )}
              </div>
              <div className="w__sale_view">
                {fixed && <div className="sale_posted_at">落札されました</div>}
                {closedReason === CLOSED_REASON.EXPIRED && (
                  <div className="sale_posted_at">出品期限が切れました</div>
                )}
                {closedReason === CLOSED_REASON.CANCELED && (
                  <div className="sale_posted_at">取り下げられました</div>
                )}
                {closedReason === CLOSED_REASON.FAILED_CHARGING && (
                  <div className="sale_posted_at">決済エラーが起きました</div>
                )}
                {fixed ||
                closedReason === CLOSED_REASON.EXPIRED ||
                closedReason === CLOSED_REASON.CANCELED ||
                closedReason === CLOSED_REASON.FAILED_CHARGING ? null : (
                  <div>
                    <div className="sale_posted_at">
                      {closeBidAt}
                      まで
                      <br />
                      {diff < AN_HOUR ? <div>残り{diff}分未満</div> : null}
                    </div>
                    <div className="sale_time_attention">
                      <p>
                        ※残り時間は目安であり通信環境やシステム状況などから
                        <br />
                        誤差が生じる場合があります。
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <SnsIconRow />
              {users.mydata.id !== sales.item.sellerUserId ? (
                <div className="w__report-balme">
                  <div
                    className="sale-report"
                    onClick={() => setReportModal()}
                    role="presentation"
                  >
                    <p>この出品を通報する</p>
                  </div>
                </div>
              ) : null}
              <div className="sale_present_information mobile_view">
                <div className="grid_sale_main_option">
                  <span>{expenseText}</span>
                </div>
              </div>
              <div className="sale_present_price-wrapper">
                <Header as="h3" className="sale_present_price">
                  ¥{getLocaleStringLocales(displayPrice)}
                </Header>
                {shippingCost && prefecture && sellerUserId !== id ? (
                  <Button className="sale_shipping_cost" onClick={handleClick}>
                    配送料一覧
                  </Button>
                ) : null}
              </div>
              <div className="sale_prepayment">
                ※元払い出品の送料については
                <Link to={paths.introduction.prepayment} target="_blank">
                  こちら
                </Link>
                から
              </div>
              {window.isAuthed ? (
                <BidButton
                  isMountLoading={isMountLoading}
                  closedReason={closedReason}
                />
              ) : (
                <>
                  <BidButton />
                  {closedReason !== CLOSED_REASON.FAILED_CHARGING && (
                    <>
                      {closedReason === 2 || fixed ? null : (
                        <div className="bidder_user_message_card">
                          <span>
                            入札には、
                            <Link to={`/b/signin`}>ログイン</Link>
                            が必要です。
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {/* クレジットカードが登録されている. 住所登録されていない */}
              {window.isAuthed &&
                users.mydata.isCustomer &&
                !isValidShippingData(users.myshippingdata) &&
                users.mydata.id !== sellerUserId && (
                  <div className="bidder_user_message_card">
                    <span>
                      マイページより、
                      <Link to={`/mypage/edit`}>送付先住所などの登録</Link>
                      が必要です。
                    </span>
                  </div>
                )}
              {/* 住所が登録されていて、クレジットカードが登録されていない場合 */}
              {window.isAuthed &&
                !users.mydata.isCustomer &&
                isValidShippingData(users.myshippingdata) &&
                users.mydata.id !== sellerUserId && (
                  <div className="bidder_user_message_card">
                    <span>
                      マイページより、
                      <Link to={`/mypage/credit`}>
                        クレジットカード・デビットカードの登録
                      </Link>
                      が必要です。
                    </span>
                  </div>
                )}
              {window.isAuthed &&
                !users.mydata.isCustomer &&
                !isValidShippingData(users.myshippingdata) &&
                users.mydata.id !== sellerUserId && (
                  <div className="bidder_user_message_card">
                    <span>
                      マイページより、
                      <Link to={`/mypage/credit`}>
                        クレジットカード・デビットカードの登録
                      </Link>
                      、<Link to={`/mypage/edit`}>送付先住所などの登録</Link>
                      が必要です。
                    </span>
                  </div>
                )}
              <div
                className={classNames({
                  error_message: true,
                  display_error: displayBidsError,
                })}
              >
                {displayBidsErrorMessage}
              </div>
            </div>

            <div className="grid_sale_main tablet_view">
              <p className="sale_main_description">{explanation}</p>
              <SaleInfoList />
              <div className="icon_container">
                <FacebookShareButton type="mobile" />
                <TwitterShareButton type="mobile" explanation={explanation} />
                <LineShareButton type="mobile" />
              </div>
              {users.mydata.id !== sales.item.sellerUserId ? (
                <div className="w__sale-report-container">
                  <div
                    className="sale-report-container"
                    onClick={() => setReportModal()}
                    role="presentation"
                  >
                    <p>この出品を通報する</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={classNames({
              grid_bid_history: true,
            })}
          >
            <BidHistory
              id={props.sales.item.id}
              userId={users.mydata.id}
              sellerUserId={sellerUserId}
              images={images}
              setImages={setImages}
            />

            {(users.mydata.id === sellerUserId ||
              users.mydata.id === boughtUserId) &&
              fixed && <TradeComments shippingInfo={props.shippingInfo} />}
            {window.isAuthed ? <TradeCommentsBefore /> : null}
          </div>

          {/*通報モーダル */}
          <Modal
            isModal={openReportModal}
            handleCloseModal={setReportModal}
            ModalBody={ReportModal}
            modalProps={{ users, sales }}
          />

          {/* エラーモーダル */}
          <Modal
            isModal={props.isErrorModal}
            handleCloseModal={() => {
              props.setIsErrorModal(false);
            }}
            ModalBody={ErrorModal}
            errorMessage={props.sales.item.stripeErrorMessage}
          />

          <Modal
            className="w__fare-table"
            handleCloseModal={handleCloseModal}
            isModal={showFareTable}
            ModalBody={FareTable}
            shippingCosts={shippingCosts}
          />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <NotFound />
      </>
    );
  }
}

export default enhance((props) => <Sale {...props} />);
