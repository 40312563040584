export default [
  {
    key: '1',
    value: '欲しい魚がない',
    text: '欲しい魚がない',
  },
  {
    key: '2',
    value: '出品しても売れない',
    text: '出品しても売れない',
  },
  {
    key: '3',
    value: '使い方がわからない',
    text: '使い方がわからない',
  },
  {
    key: '4',
    value: '取引が不安である',
    text: '取引が不安である',
  },
  {
    key: '5',
    value: 'その他',
    text: 'その他',
  },
];
