import { Auth } from '@/utils';
import { isHttpStatusOK } from '@/apis/utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

/** 魚詳細を取得する */
async function getSaleById(saleID) {
  let headers;

  try {
    const token = await Auth.getIdToken();
    headers = { Authorization: `Bearer ${token}` };
  } catch (error) {
    headers = {};
  }

  // eslint-disable-next-line
  try {
    const response = await fetch(`${GO_ENDPOINT}/v1/sales/${saleID}`, {
      method: 'GET',
      headers,
    });

    if (isHttpStatusOK(response.status)) {
      return response.json();
    }

    throw new Error(response);
  } catch (error) {
    throw error;
  }
}

export default getSaleById;
