import formatDate from '@/apis/utils/formatDate';

export default function parseReview({
  id,
  is_company,
  is_deleted,
  is_enabled,
  company,
  created_at,
  updated_at,
  dist_user_id,
  sale_id,
  point,
  comment,
  source_user_id,
  display_name,
  avatar_url,
  is_email_refused,
  reply,
  fish_kind,
}) {
  return Object.assign(
    {},
    {
      id,
      isCompany: is_company,
      isDeleted: is_deleted,
      isEnabled: is_enabled,
      createdAt: formatDate(created_at),
      updatedAt: formatDate(updated_at),
      distUserId: dist_user_id,
      saleId: sale_id,
      point,
      comment,
      sourceUserID: source_user_id,
      displayName: display_name,
      avatarUrl: avatar_url,
      company,
      isEmailRefused: is_email_refused,
      reply,
      fishKind: fish_kind,
    }
  );
}
