import React, { useMemo } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import enhance from './enhancer';
import { paths } from '../../../../config';

function BuyItNowModal(props) {
  const {
    modalProps: {
      handleBuyItNowModal,
      buyItNow,
      shippingCost,
      coolDeliveryCost,
      shippingRelayCost,
      fixPrice,
      shippingExpense,
      isBidPopup,
    },
    redListCheck,
    rareSpeciesCheck,
    parasiteCheck,
    toggleRedList,
    toggleRareSpecies,
    toggleParasiteCheck,
    toggleIslandCheck,
    islandCheck,
  } = props;

  const submitDisabled = useMemo(() => {
    if (isBidPopup) return false;

    if (!redListCheck || !rareSpeciesCheck || !parasiteCheck || !islandCheck)
      return true;
  }, [redListCheck, rareSpeciesCheck, parasiteCheck, islandCheck, isBidPopup]);

  return (
    <div id="sale_buy_it_now_modal">
      <div className="w__fix_message">
        <p>即決価格で落札しますか？</p>
        <p className="caution_text">※「落札する」を押すと決済を行います。</p>
      </div>

      {!isBidPopup && (
        <>
          <div className="compliance_wrap">
            <div className="compliance_red_list_wrap">
              <Checkbox
                onClick={toggleRedList}
                className="compliance_checkbox"
                id="buy_modal_red_list_checkbox"
              />
              <div>
                <label
                  htmlFor="buy_modal_red_list_checkbox"
                  className="compliance_label"
                >
                  落札する商品は絶滅危惧種に該当しないですか？
                </label>
                <br />
                <label
                  htmlFor="buy_modal_red_list_checkbox"
                  className="compliance_label"
                >
                  (絶滅危惧種リストは
                  <a
                    href="http://www.env.go.jp/press/files/jp/114457.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    こちら
                  </a>
                  )
                </label>
              </div>
            </div>
            <div className="compliance_rare_species_wrap">
              <Checkbox
                onClick={toggleRareSpecies}
                className="compliance_checkbox"
                id="buy_modal_rare_species_checkbox"
              />
              <div>
                <label
                  htmlFor="buy_modal_rare_species_checkbox"
                  className="compliance_label"
                >
                  落札する商品は国内希少種に該当しないですか？
                </label>
                <br />
                <label
                  htmlFor="buy_modal_rare_species_checkbox"
                  className="compliance_label"
                >
                  (国内希少種リストは
                  <a
                    href="https://www.env.go.jp/nature/kisho/domestic/list.html#gyorui"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    こちら
                  </a>
                  )
                </label>
              </div>
            </div>
            <div className="compliance-parasite-wrap">
              <Checkbox
                onClick={toggleParasiteCheck}
                className="compliance_checkbox"
                id="bit_modal_parasite_checkbox"
              />
              <label
                htmlFor="bit_modal_parasite_checkbox"
                className="compliance_label"
              >
                寄生虫が付いている可能性があります。
                <Link to={paths.introduction.cooking} target="_blank">
                  こちら
                </Link>
                をご覧いただきご注意ください。
                <br />
                (異常ではありません。)
              </label>
            </div>
          </div>

          <div className="remote-island-list-check">
            <Checkbox
              onClick={toggleIslandCheck}
              className="compliance_checkbox"
              id="remote_island_checkbox"
            />
            <div>
              <label
                htmlFor="remote_island_checkbox"
                className="compliance_label"
              >
                佐川急便を利用した元払い配送で配送先が離島の場合、決済時に別途離島中継料金が加算されます。
              </label>
              <br />
              <label
                htmlFor="remote_island_checkbox"
                className="compliance_label"
              >
                (
                <a
                  href={`${paths.common.fee}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  対象地域と料金はこちら
                </a>
                )
              </label>
            </div>
          </div>
        </>
      )}

      {shippingExpense === 3 ? (
        <table className="sale_prices">
          <thead>
            <tr>
              <th>送料</th>
              <th>離島中継料金</th>
              <th>クール料金</th>
              <th className="emphasis">決済予定価格</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>¥{shippingCost.toLocaleString()}</td>
              <td>¥{shippingRelayCost.toLocaleString()}</td>
              <td>¥{coolDeliveryCost.toLocaleString()}</td>
              <td className="sale_total_prices">
                ¥
                {(
                  Number(fixPrice) +
                  shippingCost +
                  shippingRelayCost +
                  coolDeliveryCost
                ).toLocaleString()}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      <div className="sale_bid_modal_button">
        <Button
          negative
          onClick={handleBuyItNowModal}
          className="negative_button"
        >
          キャンセル
        </Button>
        <Button
          positive
          onClick={buyItNow}
          content="落札する"
          className="positive_button"
          disabled={submitDisabled}
        />
      </div>
    </div>
  );
}

export default enhance((props) => <BuyItNowModal {...props} />);
