import area from './area';
import closeBidMinDate from './closeBidMinDate';
import closeBidMinHour from './closeBidMinHour';
import ikejimeMethod from './ikejimeMethod';
import ikejimeWater from './ikejimeWater';
import prefecture from './prefecture';
import shippingExpense from './shippingExpense';
import shippingTiming from './shippingTiming';
import leaveReasons from './leaveReason';
import deliveryMethod from './deliveryMethod';
import fishingYear from './fishingYear';
import fishingMonth from './fishingMonth';
import fishingDay from './fishingDay';
import ikejimeTiming from './ikejimeTiming';
import savingMethod from './savingMethod';
import governments from './governments';
import cleaningMethod from './cleaningMethod';
import sorts from './sorts';
import shippingSize from './shippingSize';

export default {
  area,
  closeBidMinDate,
  closeBidMinHour,
  ikejimeMethod,
  ikejimeWater,
  prefecture,
  shippingExpense,
  shippingTiming,
  leaveReasons,
  deliveryMethod,
  fishingYear,
  fishingMonth,
  fishingDay,
  ikejimeTiming,
  savingMethod,
  governments,
  cleaningMethod,
  sorts,
  shippingSize,
};
