import { getEnv, Auth } from '@/utils';
import { parseFailed } from '@/apis/utils';

const { GO_ENDPOINT } = getEnv();

async function postUserReview(data) {
  try {
    const token = await Auth.getIdToken();

    const response = await fetch(
      `${GO_ENDPOINT}/v1/users/${data.userId}/rate`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sale_id: data.saleId,
          dist_user_id: data.distUserId,
          point: data.point,
          comment: data.comment,
        }),
      }
    );
    const responseJson = await response.json();

    if (!responseJson.errors) {
      return {
        okdata: responseJson,
      };
    }

    throw new Error({
      faileddata: parseFailed(responseJson.errors[0]),
    });
  } catch (err) {
    throw new Error(err);
  }
}

export default postUserReview;
