import { Auth } from '@/utils';
import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function postSaleComment(saleId, message) {
  try {
    const token = await Auth.getIdToken();
    const response = await fetch(`${GO_ENDPOINT}/v1/sales/${saleId}/comments`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        message,
      }),
    });

    const responseJson = await response.json();
    if (responseJson.errors) {
      throw new Error(responseJson.errors[0].message);
    } else {
      return responseJson;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
}

export default postSaleComment;
