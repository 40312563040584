import * as React from 'react';
import classNames from 'classnames';
import Media from 'react-media';
import { Tab, Loader } from 'semantic-ui-react';
import Pagination from '@/migration/pagination';
import {
  ProfileHeader,
  SalesList,
  ReviewList,
  TitleBar,
} from '@/components/Common';
import enhance from './enhancer';

function UserProfile(props) {
  const {
    sales: {
      userProfile: { pages, totalCount, current },
    },
    isPaginationDisabled,
    onChangePage,
    onChangeReview,
    users: {
      userProfile,
      mydata,
      review,
      myFollowId,
      userFollowCounts,
      userFollowerCounts,
    },
    isLoading,
  } = props;

  const getIntroduction = () => {
    if (userProfile.introduction && userProfile.introduction.length !== 0) {
      return userProfile.introduction;
    }
    return <div className="noIntroduction">まだ自己紹介文はありません</div>;
  };

  return (
    <div className="app_userprofile flex">
      <Media query="(max-width: 710px)">
        {(matches) => {
          if (!matches) {
            // PC表示
            return (
              <>
                <section>
                  <ProfileHeader
                    selledCount={totalCount}
                    userName={userProfile.displayName}
                    userThumbnail={userProfile.thumnailImageUrl}
                    isShowReportButton={userProfile.id !== mydata.id}
                    averagePoint={userProfile.averagePoint}
                    totalEvaluationCount={userProfile.totalEvaluationCount}
                    followed={myFollowId.indexOf(userProfile.id) >= 0}
                    followCounts={userFollowCounts}
                    followerCounts={userFollowerCounts}
                    isLoading={isLoading}
                  />
                  <div className="userprofile_fish_title">
                    <TitleBar title="自己紹介文" />
                  </div>

                  <div className={classNames('userprofile_introduction_area')}>
                    <div className="userprofile_introduction">
                      {getIntroduction()}
                    </div>
                  </div>
                  <TitleBar title="出品した魚" />
                  <Tab.Pane
                    className={classNames({
                      userprofile_fish_list: pages.results.length === 0,
                    })}
                  >
                    {pages.active.length === 0 || isLoading ? (
                      <div>
                        <Loader
                          active={isLoading}
                          inline="centered"
                          className="fished_loading"
                        />
                        {!isLoading ? (
                          <span>まだ出品した魚はありません</span>
                        ) : null}
                      </div>
                    ) : (
                      <>
                        <div className="userprofile_fish_title" />
                        <SalesList data={pages.active} me={mydata} />
                        <div>
                          <Pagination
                            totalCount={totalCount}
                            current={current}
                            pageSize={6}
                            changePage={onChangePage}
                            disabled={isPaginationDisabled}
                          />
                        </div>
                      </>
                    )}
                  </Tab.Pane>

                  <TitleBar title="評価" id="reviewTitle" />
                  {review.pages.active.length === 0 || isLoading ? (
                    <div className="no-review">
                      <Loader
                        active={isLoading}
                        className="tiny-loader"
                        inline="centered"
                      />
                      {!isLoading ? <span>まだ評価はありません</span> : null}
                    </div>
                  ) : (
                    <div className="user_review">
                      <ReviewList
                        averagePoint={userProfile.averagePoint}
                        totalEvaluationCount={userProfile.totalEvaluationCount}
                        data={review.pages.active}
                        isLinkDisabled
                      />
                      <Pagination
                        totalCount={review.totalCount}
                        current={review.current}
                        pageSize={5}
                        changePage={onChangeReview}
                        disabled={isPaginationDisabled}
                      />
                    </div>
                  )}
                </section>
              </>
            );
          }
          // Mobile表示
          return (
            <>
              <ProfileHeader
                selledCount={totalCount}
                userName={userProfile.displayName}
                userThumbnail={userProfile.thumnailImageUrl}
                isShowReportButton={userProfile.id !== mydata.id}
                averagePoint={userProfile.averagePoint}
                totalEvaluationCount={userProfile.totalEvaluationCount}
                followed={myFollowId.indexOf(userProfile.id) >= 0}
                followCounts={userFollowCounts}
                followerCounts={userFollowerCounts}
                isLoading={isLoading}
              />
              <div className="userprofile_fish_title">
                <TitleBar title="自己紹介文" />
              </div>
              <div className="userprofile_introduction_area">
                <div className="userprofile_introduction">
                  {getIntroduction()}
                </div>
              </div>

              <div className="contents">
                <TitleBar title="出品した魚" />

                <Tab.Pane
                  className={classNames({
                    userprofile_fish_list: pages.results.length === 0,
                  })}
                >
                  {pages.active.length === 0 || isLoading ? (
                    <div>
                      <Loader
                        active={isLoading}
                        inline="centered"
                        className="fished_loading"
                      />
                      {!isLoading ? (
                        <span>まだ出品した魚はありません</span>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <div className="userprofile_fish_title" />
                      <SalesList data={pages.active} me={mydata} />
                      <div>
                        <Pagination
                          totalCount={totalCount}
                          current={current}
                          pageSize={6}
                          changePage={onChangePage}
                          disabled={isPaginationDisabled}
                        />
                      </div>
                    </>
                  )}
                </Tab.Pane>

                <TitleBar title="評価" id="reviewTitle" />
                {review.pages.active.length === 0 || isLoading ? (
                  <div className="no-review">
                    <Loader
                      active={isLoading}
                      className="tiny-loader"
                      inline="centered"
                    />
                    {!isLoading ? <span>まだ評価はありません</span> : null}
                  </div>
                ) : (
                  <div className="user_review">
                    <ReviewList
                      averagePoint={userProfile.averagePoint}
                      totalEvaluationCount={userProfile.totalEvaluationCount}
                      data={review.pages.active}
                      isLinkDisabled
                    />
                    <Pagination
                      totalCount={review.totalCount}
                      current={review.current}
                      pageSize={5}
                      changePage={onChangeReview}
                      disabled={isPaginationDisabled}
                    />
                  </div>
                )}
              </div>
            </>
          );
        }}
      </Media>
    </div>
  );
}

export default enhance((props) => <UserProfile {...props} />);
