import { base } from '../../utils';

export const getShippingRelayCosts = async () => {
  try {
    const url = `/v1/shipping_relay_costs`;

    const response = await base.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
