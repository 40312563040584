import axios from 'axios';
import { Auth } from '@/utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

export const deleteUserShipping = async (shippingInfoId) => {
  try {
    const token = await Auth.getIdToken();

    await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .delete(`${GO_ENDPOINT}/v1/shipping_info/${shippingInfoId}`);
  } catch (error) {
    throw new Error(error);
  }
};
