/* @flow */
import statusCategory from './statusCategory';
import images from './images';
import prices from './prices';

export default {
  fishes: {
    statusCategory,
  },
  images,
  logo: 'https://fishsale.jp/assets/logo@2x.png',
  logoVertical: 'https://fishsale.jp/assets/logo_vertical_tm.png',
  logo_inverted: 'https://fishsale.jp/assets/b2%403x+(1).png',
  logo_inverted_vertical_tm:
    'https://fishsale.jp/assets/logo_inverted_vertical_tm.png',
  heroImage_top_mobile: '',
  footer_ocean: 'https://fishsale.jp/assets/footer-ocean.jpg',
  icon_magnifier: 'https://fishsale.jp/assets/icon-magnifier.png',
  icon_privacy: 'https://fishsale.jp/assets/common-title-decoration.png',
  icon_sns_facebook: 'https://fishsale.jp/assets/icon-facebook.png',
  icon_login_facebook: 'https://fishsale.jp/assets/FB-f-Logo__blue_50.png',
  icon_sns_twitter: 'https://fishsale.jp/assets/icon-twitter.png',
  icon_sns_line: 'https://fishsale.jp/assets/icon-line.png',
  selling_feature_smartphone_pc:
    'https://fishsale.jp/assets/selling-feature-smartphone-pc.png',
  selling_feature_dollar_pc:
    'https://fishsale.jp/assets/selling-feature-dollar-pc.png',
  selling_feature_fish_pc:
    'https://fishsale.jp/assets/selling-feature-fish-pc.png',
  selling_feature_decoration_bar:
    'https://fishsale.jp/assets/selling-feature-decoration-bar.svg',
  fish_facebook_icon: 'https://fishsale.jp/assets/facebook.png',
  fish_instagram_icon: 'https://fishsale.jp/assets/instagram.png',
  fish_google_icon: 'https://fishsale.jp/assets/google.png',
  profile_header_bg: 'https://fishsale.jp/assets/profile-header-bg.jpg',
  human_icon: 'https://fishsale.jp/assets/human-icon.png',
  download_icon: 'https://fishsale.jp/assets/download%403x.png',
  account_1: 'https://fishsale.jp/assets/introduction-account.png',
  account_2: 'https://fishsale.jp/assets/introduction-account2.png',
  account_3: 'https://fishsale.jp/assets/account_3%403x.png',
  account_4: 'https://fishsale.jp/assets/account_4%403x.png',
  banner_1: 'https://fishsale.jp/assets/banner_1%403x.png',
  banner_2: 'https://fishsale.jp/assets/banner_2%403x.png',
  banner_3: 'https://fishsale.jp/assets/banner_3%403x.png',
  banner_4: 'https://fishsale.jp/assets/banner_4%403x.png',
  fee_1: 'https://fishsale.jp/assets/service-fee.png',
  sell_1: 'https://fishsale.jp/assets/introduction-sell.png',
  sell_2: 'https://fishsale.jp/assets/introduction-sell2-1.png',
  sell_3: 'https://fishsale.jp/assets/sell-guide_3%403x.png',
  sell_4: 'https://fishsale.jp/assets/sell-guide_4%403x.png',
  sell_5: 'https://fishsale.jp/assets/sell-guide_5%403x.png',
  notfound_logo: 'https://fishsale.jp/assets/logo_404.png',
  delivery: {
    prices,
  },
};
