// eslint-disable @typescript-eslint/camelcase

import { Record, Seq } from 'immutable';
import camelCaseKeys from 'camelcase-keys';
import { makeRemoteActiveWithImmutable } from '@/migration/pagination/makeRemoteActiveWithImmutable';
import {
  parseSales,
  parseSale,
  parseSaleComments,
  parseSaleCommentsBefore,
} from './utils';

const SalesStateRecord = Record({
  isFetching: false,
  data: [],

  /** すべて */
  fishes: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },

  /** 業者用 */
  companyFishes: {
    current: 1,
    data: [],
    totalCount: 0,
    totalPages: 0,
  },

  /** 個人 */
  personalFishes: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
    searchQuery: '',
  },

  kansaiData: [],
  searchData: [],
  userProfile: {
    pages: {
      results: [],
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },
  ownExhibits: {
    totalCount: 0,
  },
  category: '0',
  area: '0',
  item: {
    id: 0,
    sellerName: '',
    fishKind: '',
    isNature: true,
    explanation: '',
    saleImageSrc: '',
    size: '',
    action: '',
    weight: '',
    fishing_spot: '',
    value: 0,
    fixed: false,
    fishingSpotDetail: '',
    weightAdditionalInfo: '',
    bidderUserId: 0,
    methodText: '',
    expenseText: '',
    timingText: '',
    area: '',
    imageURL: '',
    images: [],
    boughtUserId: 0,
    fixPrice: 0,
    weightRemarks: '',
    closeBidAt: '',
    sellerUserId: 0,
    bidInfo: [],
    price: 0,
    shippingMethod: '',
    bid: false,
    closedReason: false,
    deliveryText: '',
    ikejimeText: '',
    waterText: '',
    saveText: '',
    displayPrice: 0,
    userRated: false,
    cleaningText: '',
    lastUpdatedAt: '',
    stripeErrorMessage: '',
  },
  comments: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },
  commentsBefore: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },
  itemBids: [],
  itemComments: [],
  sellerItems: {
    totalCount: 0,
    results: [],
  },
  ownItems: {
    underExhibition: [],
    bidding: [],
    sold: [],
    bought: [],
    sales: [],
  },
  landing: {
    data: [],
  },
  mypage: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },
  owner: {
    all: {
      data: [],
      counts: 0,
    },
    bidOfBidNow: {
      data: [],
      counts: 0,
    },
    bidOfSoldOut: {
      data: [],
      counts: 0,
    },
    exhibitOfExhibitNow: {
      data: [],
      counts: 0,
    },
    exhibitOfSeriNow: {
      data: [],
      counts: 0,
    },
    exhibitOfSoldOut: {
      data: [],
      counts: 0,
    },
  },
});

class SalesState extends SalesStateRecord {
  getSaleBids(state, action) {
    const bids = action.payload;
    bids.sort((a, b) => {
      if (a.postedAt < b.postedAt) {
        return 1;
      }
      if (a.postedAt > b.postedAt) {
        return -1;
      }
      return 0;
    });
    const nextState = state.withMutations((draftState) => {
      draftState.set('itemBids', bids);

      return draftState;
    });

    return nextState;
  }

  getSaleById(state, action) {
    const nextState = state.withMutations((draftState) => {
      const _item = camelCaseKeys(action.payload);
      const parsedItem = parseSale(action.payload);
      const item = Object.assign(_item, parsedItem);

      draftState.set('item', item);

      return draftState;
    });

    return nextState;
  }

  getSalesForLanding(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.setIn(['landing', 'data'], action.payload.data.results);

      return draftState;
    });

    return nextState;
  }

  getSales(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('data', action.payload.results);

      return draftState;
    });

    return nextState;
  }

  getOwnExhibitsCounts(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState.setIn(['ownExhibits', 'totalCount'], payload.totalCount);
      return draftState;
    });

    return nextState;
  }

  // 魚一覧 / すべて・個人
  getSalesWithPagination(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.page && draftState.setIn(['fishes', 'current'], payload.page);

      draftState
        .setIn(['fishes', 'totalCount'], payload.totalCount)
        .setIn(['fishes', 'totalPages'], payload.totalPages)
        .setIn(
          ['fishes', 'pages', 'active'],
          parseSales(payload.pages.active || [])
        )
        .setIn(
          ['fishes', 'pages', 'first'],
          parseSales(payload.pages.first || [])
        )
        .setIn(
          ['fishes', 'pages', 'last'],
          parseSales(payload.pages.last || [])
        )
        .setIn(
          ['fishes', 'pages', 'before_distant'],
          parseSales(payload.pages.before_distant || [])
        )
        .setIn(
          ['fishes', 'pages', 'before_near'],
          parseSales(payload.pages.before_near || [])
        )
        .setIn(
          ['fishes', 'pages', 'after_near'],
          parseSales(payload.pages.after_near || [])
        )
        .setIn(
          ['fishes', 'pages', 'after_distant'],
          parseSales(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  // 魚一覧 / すべて・個人
  getSalesPagination(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.page && draftState.setIn(['fishes', 'current'], payload.page);

      draftState
        .setIn(['fishes', 'totalCount'], payload.totalCount)
        .setIn(['fishes', 'totalPages'], payload.totalPages)
        .setIn(
          ['fishes', 'pages', 'active'],
          parseSales(payload.pages.active || [])
        )
        .setIn(
          ['fishes', 'pages', 'first'],
          parseSales(payload.pages.first || [])
        )
        .setIn(
          ['fishes', 'pages', 'last'],
          parseSales(payload.pages.last || [])
        )
        .setIn(
          ['fishes', 'pages', 'before_distant'],
          parseSales(payload.pages.before_distant || [])
        )
        .setIn(
          ['fishes', 'pages', 'before_near'],
          parseSales(payload.pages.before_near || [])
        )
        .setIn(
          ['fishes', 'pages', 'after_near'],
          parseSales(payload.pages.after_near || [])
        )
        .setIn(
          ['fishes', 'pages', 'after_distant'],
          parseSales(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  // 魚一覧 / 会社
  getSalesCompany(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState.setIn(['companyFishes', 'current'], payload.currentPage);
      draftState.setIn(['companyFishes', 'totalCount'], payload.totalCount);
      draftState.setIn(['companyFishes', 'totalPages'], payload.totalPages);
      draftState.setIn(['companyFishes', 'data'], payload.data);

      return draftState;
    });

    return nextState;
  }

  getUnratedWithPagination(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.page && draftState.setIn(['fishes', 'current'], payload.page);

      draftState
        .setIn(['mypage', 'totalCount'], payload.totalCount)
        .setIn(['mypage', 'totalPages'], payload.totalPages)
        .setIn(
          ['mypage', 'pages', 'active'],
          parseSales(payload.pages.active || [])
        )
        .setIn(
          ['mypage', 'pages', 'first'],
          parseSales(payload.pages.first || [])
        )
        .setIn(
          ['mypage', 'pages', 'last'],
          parseSales(payload.pages.last || [])
        )
        .setIn(
          ['mypage', 'pages', 'before_distant'],
          parseSales(payload.pages.before_distant || [])
        )
        .setIn(
          ['mypage', 'pages', 'before_near'],
          parseSales(payload.pages.before_near || [])
        )
        .setIn(
          ['mypage', 'pages', 'after_near'],
          parseSales(payload.pages.after_near || [])
        )
        .setIn(
          ['mypage', 'pages', 'after_distant'],
          parseSales(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  getSaleCommentsWithPagination(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.page && draftState.setIn(['comments', 'current'], payload.page);

      draftState
        .setIn(['comments', 'totalCount'], payload.totalCount)
        .setIn(['comments', 'totalPages'], payload.totalPages)
        .setIn(
          ['comments', 'pages', 'active'],
          parseSaleComments(payload.pages.active || [])
        )
        .setIn(
          ['comments', 'pages', 'first'],
          parseSaleComments(payload.pages.first || [])
        )
        .setIn(
          ['comments', 'pages', 'last'],
          parseSaleComments(payload.pages.last || [])
        )
        .setIn(
          ['comments', 'pages', 'before_distant'],
          parseSaleComments(payload.pages.before_distant || [])
        )
        .setIn(
          ['comments', 'pages', 'before_near'],
          parseSaleComments(payload.pages.before_near || [])
        )
        .setIn(
          ['comments', 'pages', 'after_near'],
          parseSaleComments(payload.pages.after_near || [])
        )
        .setIn(
          ['comments', 'pages', 'after_distant'],
          parseSaleComments(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  getSaleCommentsBeforePagination(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.current &&
        draftState.setIn(['commentsBefore', 'current'], payload.current);

      draftState
        .setIn(['commentsBefore', 'totalCount'], payload.totalCount)
        .setIn(['commentsBefore', 'totalPages'], payload.totalPages)
        .setIn(
          ['commentsBefore', 'pages', 'active'],
          parseSaleCommentsBefore(payload.pages.active || [])
        )
        .setIn(
          ['commentsBefore', 'pages', 'first'],
          parseSaleCommentsBefore(payload.pages.first || [])
        )
        .setIn(
          ['commentsBefore', 'pages', 'last'],
          parseSaleCommentsBefore(payload.pages.last || [])
        )
        .setIn(
          ['commentsBefore', 'pages', 'before_distant'],
          parseSaleCommentsBefore(payload.pages.before_distant || [])
        )
        .setIn(
          ['commentsBefore', 'pages', 'before_near'],
          parseSaleCommentsBefore(payload.pages.before_near || [])
        )
        .setIn(
          ['commentsBefore', 'pages', 'after_near'],
          parseSaleCommentsBefore(payload.pages.after_near || [])
        )
        .setIn(
          ['commentsBefore', 'pages', 'after_distant'],
          parseSaleCommentsBefore(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  getSalesForMyPage(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      payload.page && draftState.setIn(['mypage', 'current'], payload.page);

      draftState
        .setIn(['mypage', 'totalCount'], payload.totalCount)
        .setIn(['mypage', 'totalPages'], payload.totalPages)
        .setIn(
          ['mypage', 'pages', 'active'],
          parseSales(payload.pages.active || [])
        )
        .setIn(
          ['mypage', 'pages', 'first'],
          parseSales(payload.pages.first || [])
        )
        .setIn(
          ['mypage', 'pages', 'last'],
          parseSales(payload.pages.last || [])
        )
        .setIn(
          ['mypage', 'pages', 'before_distant'],
          parseSales(payload.pages.before_distant || [])
        )
        .setIn(
          ['mypage', 'pages', 'before_near'],
          parseSales(payload.pages.before_near || [])
        )
        .setIn(
          ['mypage', 'pages', 'after_near'],
          parseSales(payload.pages.after_near || [])
        )
        .setIn(
          ['mypage', 'pages', 'after_distant'],
          parseSales(payload.pages.after_distant || [])
        );

      return draftState;
    });

    return nextState;
  }

  getSalesForUserProfile(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['userProfile', 'totalCount'],
          payload.resultWithPagination.totalCount
        )
        .setIn(
          ['userProfile', 'totalPages'],
          payload.resultWithPagination.totalPages
        )
        .setIn(
          ['userProfile', 'pages', 'active'],
          parseSales(payload.resultWithPagination.pages.active || [])
        )
        .setIn(
          ['userProfile', 'pages', 'first'],
          parseSales(payload.resultWithPagination.pages.first || [])
        )
        .setIn(
          ['userProfile', 'pages', 'last'],
          parseSales(payload.resultWithPagination.pages.last || [])
        )
        .setIn(
          ['userProfile', 'pages', 'before_distant'],
          parseSales(payload.resultWithPagination.pages.before_distant || [])
        )
        .setIn(
          ['userProfile', 'pages', 'before_near'],
          parseSales(payload.resultWithPagination.pages.before_near || [])
        )
        .setIn(
          ['userProfile', 'pages', 'after_near'],
          parseSales(payload.resultWithPagination.pages.after_near || [])
        )
        .setIn(
          ['userProfile', 'pages', 'after_distant'],
          parseSales(payload.resultWithPagination.pages.after_distant || [])
        )
        .setIn(['userProfile', 'pages', 'results'], payload.results);

      return draftState;
    });

    return nextState;
  }

  initializeSalesForMyPage(state) {
    const nextState = state.withMutations((draftState) => {
      draftState.setIn(['mypage', 'current'], 1);

      draftState
        .setIn(['mypage', 'totalCount'], 0)
        .setIn(['mypage', 'totalPages'], 0)
        .setIn(['mypage', 'pages', 'active'], parseSales([]))
        .setIn(['mypage', 'pages', 'first'], parseSales([]))
        .setIn(['mypage', 'pages', 'last'], parseSales([]))
        .setIn(['mypage', 'pages', 'before_distant'], parseSales([]))
        .setIn(['mypage', 'pages', 'before_near'], parseSales([]))
        .setIn(['mypage', 'pages', 'after_near'], parseSales([]))
        .setIn(['mypage', 'pages', 'after_distant'], parseSales([]));

      return draftState;
    });

    return nextState;
  }

  updateFishesPagesActive(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['fishes', 'pages', 'active'],
          makeRemoteActiveWithImmutable(
            draftState,
            ['fishes', 'pages'],
            state.fishes.current,
            payload.current,
            state.fishes.totalPages
          )
        )
        .setIn(['fishes', 'current'], payload.current);

      return draftState;
    });

    return nextState;
  }

  updateUserProfilePageActive(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['userProfile', 'pages', 'active'],
          makeRemoteActiveWithImmutable(
            draftState,
            ['userProfile', 'pages'],
            state.userProfile.current,
            payload.current,
            state.userProfile.totalPages
          )
        )
        .setIn(['userProfile', 'current'], payload.current);

      return draftState;
    });

    return nextState;
  }

  updateCommentsPagesActive(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['comments', 'pages', 'active'],
          makeRemoteActiveWithImmutable(
            draftState,
            ['comments', 'pages'],
            state.comments.current,
            payload.current,
            state.comments.totalPages
          )
        )
        .setIn(['comments', 'current'], payload.current);

      return draftState;
    });

    return nextState;
  }

  updateCommentsBeforePagesActive(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['commentsBefore', 'pages', 'active'],
          makeRemoteActiveWithImmutable(
            draftState,
            ['commentsBefore', 'pages'],
            state.commentsBefore.current,
            payload.current,
            state.commentsBefore.totalPages
          )
        )
        .setIn(['commentsBefore', 'current'], payload.current);

      return draftState;
    });

    return nextState;
  }

  updateMyPagePagesActive(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState
        .setIn(
          ['mypage', 'pages', 'active'],
          makeRemoteActiveWithImmutable(
            draftState,
            ['mypage', 'pages'],
            state.mypage.current,
            payload.current,
            state.mypage.totalPages
          )
        )
        .setIn(['mypage', 'current'], payload.current);

      return draftState;
    });

    return nextState;
  }

  postSale(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('item', action.payload.okdata);

      return draftState;
    });

    return nextState;
  }

  searchSales(state, action) {
    const { payload } = action;

    const nextState = state.withMutations((draftState) => {
      draftState.set('data', action.payload.results);

      const doneData = draftState.get('data').filter((value) => {
        if (
          payload.category === '全てのカテゴリ' &&
          payload.area === '全ての地域'
        ) {
          return value;
        }

        if (payload.category === '全てのカテゴリ') {
          return value.area === payload.area;
        }

        if (payload.area === '全ての地域') {
          return value.category === payload.category;
        }
        return (
          value.category === payload.category && value.area === payload.area
        );
      });

      draftState.set('searchData', doneData);
      draftState.set('category', payload.category);
      draftState.set('area', payload.area);

      return draftState;
    });

    return nextState;
  }

  postSaleBids(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('item', action.payload);

      return draftState;
    });

    return nextState;
  }

  updateBids(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('itemComments', action.payload);

      return draftState;
    });

    return nextState;
  }

  getSaleComments(state, action) {
    const ItemCommentsRecord = Record({
      id: 0,
      content: '',
      name: '',
      icon: 'https://fishsale.jp/assets/noimage_normal.png',
    });

    const nextItemComments = Seq(action.payload.results)
      .map((value) => {
        return new ItemCommentsRecord({
          id: value.id,
          content: value.body,
          name: value.user_display_name,
          icon: value.icon,
        });
      })
      .toJS();

    const nextState = state.withMutations((draftState) => {
      draftState.set('itemComments', nextItemComments);

      return draftState;
    });

    return nextState;
  }

  deleteSale(state) {
    return state;
  }

  postSaleComment(state) {
    return state;
  }

  postSaleCommentBefore(state) {
    return state;
  }

  getSalesBySeller(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('sellerHavingData', action.payload);

      return draftState;
    });

    return nextState;
  }

  cleanSaleComments(state, action) {
    const nextState = state.withMutations((draftState) => {
      draftState.set('itemComments', action.payload);

      return draftState;
    });

    return nextState;
  }

  getSalesOwnerAll(state, action) {
    const nextState = state.withMutations((draftState) => {
      !action.payload && draftState.set('isFetching', true);
      action.payload &&
        draftState.setIn(['owner', 'all', 'data'], action.payload.results);
      action.payload &&
        draftState.setIn(['owner', 'all', 'counts'], action.payload.totalCount);
      action.payload && draftState.set('isFetching', false);

      return draftState;
    });

    return nextState;
  }
}

export default SalesState;
