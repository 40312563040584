import { FormLabelIcon, TitleBar } from '@/components/Common';
import * as React from 'react';
import { Field } from 'redux-form/immutable';
import { Button } from 'semantic-ui-react';
import { FieldInput, FieldTextArea } from '../../Form';
import enhance from './enhancer';

function Contact(props) {
  const { submitSendMail, handleSubmit } = props;

  return (
    <div className="app_contact flex">
      <div className="wrapper">
        <TitleBar title="お問い合わせ" />

        <form className="flex" onSubmit={handleSubmit(submitSendMail)}>
          <div className="amplify-container">
            <div className="contact_form_title">
              お名前
              <span className="word_count">(最大40文字)</span>
              <FormLabelIcon value="任意" optional />
            </div>
            <div className="w__field">
              <Field
                name="name"
                className="name"
                placeholder="例)魚太郎"
                component={FieldInput}
                maxLength={41}
              />
            </div>
            <div className="contact_form_title">
              メールアドレス
              <span className="word_count">(最大255文字)</span>
              <FormLabelIcon value="必須" />
            </div>
            <div className="w__field">
              <Field
                name="email"
                className="email"
                placeholder="例)fish@sakana.ne.jp"
                component={FieldInput}
                maxLength={256}
                type="email"
                autoCorrect="off"
                autoCapitalize="off"
              />
            </div>
            <div className="contact_form_title">
              内容
              <span className="word_count">(最大500文字)</span>
              <FormLabelIcon value="必須" />
            </div>
            <div className="w__field">
              <Field
                name="content"
                className="content"
                placeholder=""
                component={FieldTextArea}
                maxLength={501}
              />
            </div>
            <Button content="送信する" className="submit_login" />
          </div>
        </form>
        <div className="report_container">
          <div className="report_field">
            <p>
              違法と思われる行為を発見した方は、下記の各取締機関に直接ご通報ください。
            </p>
            <div className="report_contact">
              【通報先】
              <div className="report_item">
                ・偽装表示及び不当競争防止法に関して
                <br />
                最寄りの警察署、または警察庁ご意見箱（
                <a
                  href="https://www.npa.go.jp/npa_goiken/opinion-0001.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
                ）からご通報ください。
              </div>
              <div className="report_item">
                ・漁業法に関して
                <br />
                海上保安庁「118」番にご通報ください。
              </div>
              <div className="report_item">
                ・食品衛生法に関して
                <br />
                <a
                  href="https://koueki-search-result.caa.go.jp/prod/kensaku-sys?cgi=view&index=2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
                からご通報ください。
              </div>
              <div className="report_item">
                ・種の保存法に関して
                <br />
                環境省&nbsp;&nbsp;&nbsp;野生生物課&nbsp;&nbsp;&nbsp;
                条約法令係（03-5521-8283）、または
                <a
                  href="https://www.env.go.jp/moemail"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  こちら
                </a>
                からご通報ください。
              </div>
              <div className="report_item">
                ・国立公園法、自然環境保全法（国立自然環境保全地域）に関して
                <br />
                該当の地方環境事務所（
                <a
                  href="http://www.env.go.jp/region/list/li_1.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
                ）、または
                <a
                  href="https://www.env.go.jp/moemail"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  こちら
                </a>
                からご通報ください。
              </div>
              <div className="report_item_bottom">
                ・都道府県自然環境保全地域に関して
                <br />
                各都道府県庁にご通報ください。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhance((props) => <Contact {...props} />);
