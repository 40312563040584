import axios from 'axios';
import { Auth } from '@/utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function getSaleCommentsBeforePagination(saleId, pagination) {
  try {
    const limit = pagination.limit;
    const current = pagination.page;
    const token = await Auth.getIdToken();

    const url = `${GO_ENDPOINT}/v1/sales/${saleId}/comments_paging/before_fixed?limit=${limit}&page=${current}`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = {
      pages: res.data.pages,
      totalCount: Number(res.headers['x-total-count']),
      totalPages: Number(res.headers['x-total-pages']),
      current,
    };

    return result;
  } catch (err) {
    throw new Error(err);
  }
}

export default getSaleCommentsBeforePagination;
