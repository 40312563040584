import * as R from 'ramda';
import { connect } from 'react-redux';
import notify from '@/migration/notification';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withState,
} from 'recompose';
import { paths } from '@/config';
import { bindActionCreators } from 'redux';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { appAction, salesAction, usersAction } from '@/actions';
import { sellPreview } from '@/apis/sales/preview';
import { sell as validate } from '@/validates';
import { date, resizeDroppedImages } from '@/utils';
import { getShippingCosts, getSaleById } from '@/apis';
import qs from 'qs';
import moment from 'moment';

const mapStateToProps = (state) => {
  const formValues = getFormValues('sellForm')(state);

  return {
    router: state.router,
    users: state.users.toJS(),
    initialValues: {
      is_nature: true,
      ikejime_water: 0,
    },
    formValues: formValues ? formValues.toJS() : {},
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...appAction, ...salesAction, ...usersAction, notify },
    dispatch
  );

const enhance = compose(
  setDisplayName('Sell'),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'sellForm',
    initialValues: {
      fishing_year: '1',
      ikejime_method: 101,
      ikejime_water: 0,
    },
    enableReinitialize: true,
    validate,
    onSubmitFail() {
      if (document.getElementById('error')) {
        document.getElementById('error').scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'nearest',
        });
      }
      notify('入力内容をご確認ください。', 'error', undefined, 5);
    },
  }),
  withState('preSaleImage', 'uploadPreSaleImage', ''),
  withState('viewSaleImage', 'uploadViewSaleImage', ''),
  withState('isImageLoading', 'uploadIsImageLoading', false),
  withState('isSellLoading', 'isSellLoadingUpdater', false),
  withState('isLoading', 'updateLoading', true),
  withState('shippingCosts', 'setShippingCosts', {}),
  withState('modalIsOpen', 'setModalIsOpen', false),
  withState('postData', 'setPostData', {}),
  withState('previewData', 'setPreviewData', {}),
  withState('showErrorMessage', 'setShowErrorMessage', ''),
  withHandlers({
    submitBiddingRegistration: (props) => async (immutableValues) => {
      const {
        setModalIsOpen,
        setPostData,
        setPreviewData,
        isSellLoadingUpdater,
        setShowErrorMessage,
      } = props;
      const values = immutableValues.toJS();

      try {
        isSellLoadingUpdater(true);

        let thumbnailTopImage = '';
        let originThumbnailTopImage = '';
        if (values.sellImages) {
          if (values.sellImages[0].isCloneImage) {
            originThumbnailTopImage = values.sellImages[0].original;
            thumbnailTopImage = values.sellImages[0].original;
          }

          if (!values.sellImages[0].isCloneImage) {
            originThumbnailTopImage = values.sellImages[0].original;
            const tmpImage = await resizeDroppedImages(
              values.sellImages.slice(0, 1)
            );

            if (tmpImage && Array.isArray(tmpImage)) {
              thumbnailTopImage = tmpImage[0];
            }
          }
        }

        const restSellImages = values.sellImages.filter((sellImage) => {
          return sellImage.original !== originThumbnailTopImage;
        });

        const images = [
          thumbnailTopImage,
          ...restSellImages
            .filter((sellImage) => sellImage.isCloneImage)
            .map((img) => img.original),
          ...(await resizeDroppedImages(
            restSellImages.filter((sellImage) => !sellImage.isCloneImage)
          )),
        ];

        const now = new Date();

        // 期限までのN分後を計算
        const close_bid_min =
          (values.close_bid_min_date - 1) * 24 * 60 +
          (values.close_bid_min_hour - now.getHours() - 1) * 60;

        let fishingDay = '';
        if (values.fishing_year && values.fishing_month && values.fishing_day) {
          fishingDay = date.margeFisingDay(
            values.fishing_year,
            values.fishing_month,
            values.fishing_day
          );
        }

        const postData = {
          fish_kind: values.fish_kind,
          is_nature: values.is_nature,
          price: parseInt(values.price, 10),
          fix_price: parseInt(values.fix_price, 10),
          ikejime_method: values.ikejime_method,
          is_ikejime: values.is_ikejime,
          ikejime_water: values.ikejime_water,
          shipping_expense: values.shipping_expense,
          size: parseInt(values.size, 10),
          weight: parseInt(values.weight, 10),
          weight_additional_info: values.weight_additional_info,
          fishing_spot_detail: values.fishing_spot_detail,
          fishing_spot: values.fishing_spot,
          hide_fishing_spot: values.hide_fishing_spot === '非公開',
          images,
          area: values.area,
          explanation: values.explanation,
          shipping_timing: values.shipping_timing,
          close_bid_min: close_bid_min,
          ikejime_timing: values.ikejime_timing,
          saving_method: values.saving_method,
          delivery_method: values.delivery_method,
          fishing_day: fishingDay,
          is_vacuum: values.is_vacuum,
          cleaning_method: values.cleaning_method,
          shipping_size: values.shipping_size,
          is_land_after_fix: values.is_land_after_fix,
        };

        const { data: confirmData } = await sellPreview(postData);

        setPostData(confirmData);
        setPreviewData(confirmData);
        setShowErrorMessage('');
        setModalIsOpen(true);
      } catch (error) {
        switch (error.message) {
          case 'contain ban words':
            notify('不適切な表現が含まれています。', 'error', undefined, 5);
            break;

          case 'number of sale limit per week exceeded':
            notify(
              '月曜～日曜の出品上限数（乱獲防止対策）を超えたため、出品できません。',
              'error',
              undefined,
              5
            );
            break;

          case 'contain inappropriate images':
          case 'contain human images':
          case 'contain denial fish images':
          case 'uncontain fish images':
            notify(
              '不適切な画像が含まれているため、出品できません。',
              'error',
              undefined,
              5
            );
            break;
        }
      } finally {
        isSellLoadingUpdater(false);
      }
    },

    confirmSubmit: (props) => async () => {
      const {
        postData,
        postSale,
        isSellLoadingUpdater,
        history,
        setShowErrorMessage,
      } = props;

      try {
        isSellLoadingUpdater(true);

        const result = await postSale(postData);

        // eslint-disable-next-line
        if (result.value.hasOwnProperty('errors')) {
          throw new Error(result.value.errors[0].message);
        }

        notify('新しく魚を出品しました', 'info', undefined, 5);
        history.push(paths.mypage.root);
      } catch (error) {
        switch (error.message) {
          case 'contain ban words': {
            setShowErrorMessage('不適切な表現が含まれています。');
            break;
          }

          case 'number of sale limit per week exceeded': {
            setShowErrorMessage(
              '月曜～日曜の出品上限数（乱獲防止対策）を超えたため、出品できません。'
            );
            break;
          }

          case 'contain inappropriate images':
          case 'contain human images':
          case 'contain denial fish images':
          case 'uncontain fish images': {
            setShowErrorMessage(
              '不適切な画像が含まれているため、出品できません。'
            );
            break;
          }
        }
      } finally {
        isSellLoadingUpdater(false);
      }
    },
  }),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const { form, change } = this.props;
      const prevFishingSpot = R.path(
        ['sellForm', 'values', 'fishing_spot'],
        prevProps.form
      );
      const fishingSpot = R.path(['sellForm', 'values', 'fishing_spot'], form);

      if (prevFishingSpot && prevFishingSpot !== fishingSpot) {
        fishingSpot === 0 && change('fishing_spot', null);
      }
    },
    async componentDidMount() {
      const {
        updateLoading,
        getMe,
        change,
        location: { search },
      } = this.props;
      await getMe();
      await this.props.getUserCards(this.props.users.mydata.id);
      const shippingCosts = await getShippingCosts(this.props.users.mydata.id);
      this.props.setShippingCosts(shippingCosts);
      const { id } = qs.parse(search, { ignoreQueryPrefix: true });

      if (id) {
        const data = await getSaleById(id);

        for (const [key, value] of Object.entries(data)) {
          if (key === 'fishing_day') {
            change('fishing_year', moment(value).format('YYYY'));
            change('fishing_month', moment(value).format('M'));
            change('fishing_day', moment(value).format('D'));
          } else if (key === 'start_price') {
            change('price', value);
          } else if (key === 'fix_price') {
            if (value !== 2147483647) {
              change(key, value);
            }
          } else if (key === 'images') {
            change('sellImages', value);
          } else {
            change(key, value);
          }
        }
      }

      updateLoading(false);
    },
  })
);

export default enhance;
