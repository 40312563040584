import * as React from 'react';
import { Link } from 'react-router-dom';
import { Field, Fields } from 'redux-form/immutable';
import classNames from 'classnames';
import { Button, Loader } from 'semantic-ui-react';
import {
  Dropdown,
  FormLabelIcon,
  TitleBar,
  RegisterPayment,
} from '@/components/Common';
import { app, areaOptions, paths } from '@/config';
import {
  FieldDropdown,
  FieldInput,
  FieldTextArea,
  FieldRedListAgreement,
  FieldRareSpeciesAgreement,
  FieldCheckboxAgreement,
  FieldSellDropzone,
  FieldIsNature,
  FieldCheckbox,
  FiealdToxicFishAgreement,
  FieldSaleDate,
  FieldIkejimeWaterRadio,
} from '../../Form';
import enhance from './enhancer';
import { prefecture } from './misc';

import SellConfirmModal from './SellConfirmModal';
import Modal from '@/migration/modal';

function Sell(props) {
  const {
    submitBiddingRegistration,
    formValues: { shipping_expense },
    handleSubmit,
    users,
    isLoading,
    modalIsOpen,
    setModalIsOpen,
    previewData,
    confirmSubmit,
    showErrorMessage,
    setShowErrorMessage,
  } = props;

  return (
    <div className="app_sell flex">
      <div
        className={classNames({
          wrap: true,
        })}
      >
        {/* users.myCreditCards.lengthの値を取りに行っている間Loaderを出す */}
        {isLoading ? (
          <div>
            <Loader
              active={isLoading}
              inline="centered"
              className="sell_loading"
            />
          </div>
        ) : !users.mydata.isSeller && !users.myCreditCards.length ? (
          // クレジットカードと売上げ口座を登録していない場合
          <div className="bidder_user_message_card">
            <RegisterPayment code="accountAndCard" />
          </div>
        ) : !users.myCreditCards.length ? (
          // クレジットカードを登録していない場合
          <div className="w__advise-register">
            <div className="bidder_user_message_card">
              <RegisterPayment code="card" />
            </div>
          </div>
        ) : !users.mydata.isSeller ? (
          // 売上げ口座を登録していない場合
          <div className="w__advise-register">
            <div className="bidder_user_message_card">
              <RegisterPayment code="account" />
            </div>
          </div>
        ) : (
          <form
            className={classNames({
              flex: true,
              app_pointer_event_none:
                !users.mydata.isSeller || !users.myCreditCards.length,
            })}
            onSubmit={handleSubmit(submitBiddingRegistration)}
          >
            {/* タイトルと出品方法誘導文言の追加 柳生 */}
            <TitleBar title="出品情報の入力" />
            <div className="field_wrapper">
              <label className="flex attention">
                ※出品方法については
                <Link to={paths.introduction.sell} target="_blank">
                  こちら
                </Link>
                から
              </label>
              <label className="flex attention">
                ※処理方法については
                <Link to={paths.introduction.cooking} target="_blank">
                  こちら
                </Link>
                から
              </label>
              <label className="flex attention">
                ※元払い出品の送料については
                <Link to={paths.introduction.prepayment} target="_blank">
                  こちら
                </Link>
                から
              </label>
            </div>
            {/* 出品画像表示位置をfield_wrapperでラッピング 柳生 */}
            <div className="field_wrapper image_group">
              <Field
                name="sellImages"
                component={FieldSellDropzone}
                type="image"
                change={props.change}
                users={props.users}
                uploadIsImageLoading={props.uploadIsImageLoading}
                isImageLoading={props.isImageLoading}
              />
            </div>
            <div className="field_wrapper">
              <label className="flex">
                商品名
                <span className="word_count">(最大50文字)</span>
                <FormLabelIcon value="必須" />
              </label>
              <Field
                name="fish_kind"
                type="text"
                placeholder="例) タイ"
                component={FieldInput}
                disabled={!users.mydata.isSeller}
                maxLength={50}
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                サイズ
                <span className="word_count">(最大10,000cm)</span>
                <FormLabelIcon value="任意" optional />
              </label>
              <Field
                name="size"
                placeholder="例) 30"
                label={{ basic: true, content: 'cm' }}
                labelPosition="right"
                disabled={!users.mydata.isSeller}
                component={FieldInput}
                maxLength={5}
              />
            </div>
            <div className="field_wrapper">
              <label className="flex">
                重量
                <span className="word_count">(最大10,000,000g)</span>
                <FormLabelIcon value="任意" optional />
              </label>
              <div className="weight_wrap">
                <Field
                  name="weight"
                  placeholder="例) 1500"
                  label={{ basic: true, content: 'g' }}
                  labelPosition="right"
                  disabled={!users.mydata.isSeller}
                  component={FieldInput}
                  maxLength={8}
                />
              </div>
              <Field
                name="weight_additional_info"
                placeholder="備考）頭は落としています"
                disabled={!users.mydata.isSeller}
                component={FieldInput}
                maxLength={50}
              />
              <span className="word_count_under">(最大50文字)</span>
            </div>

            <div className="field_wrapper">
              <label className="flex">
                釣り場（産地）
                <FormLabelIcon value="任意" optional />
              </label>
              <div className="fishing_spot_wrap">
                <Field
                  search
                  fluid
                  className="prefecture"
                  name="fishing_spot"
                  component={Dropdown}
                  placeholder="選択してください"
                  options={prefecture}
                  noResultsMessage="該当する釣り場はありません"
                />
              </div>
              <Field
                name="fishing_spot_detail"
                placeholder="詳細ポイント）明石海峡・加太 など"
                disabled={!users.mydata.isSeller}
                component={FieldInput}
                maxLength={50}
              />
              <span className="word_count_under">(最大50文字)</span>
            </div>

            <div className="field_wrapper fishing_date">
              <label className="flex">
                釣り上げた日（水揚げ日）
                <FormLabelIcon value="任意" optional />
              </label>
              <div className="w__date-wrapper">
                <Fields
                  names={[
                    'fishing_date',
                    'fishing_year',
                    'fishing_month',
                    'fishing_day',
                  ]}
                  component={FieldSaleDate}
                />
              </div>
            </div>

            <div className="field_wrapper">
              <Field
                name="is_land_after_fix"
                className="w__is_ikejime"
                component={FieldCheckbox}
                label="落札後水揚げ"
              />
            </div>

            <div className="field_wrapper is_nature">
              <label className="flex">
                天然・養殖の選択
                <FormLabelIcon value="必須" />
              </label>
              <Field
                name="is_nature"
                className="is-nature-radio"
                disabled={!users.mydata.isSeller}
                component={FieldIsNature}
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                締め方
                <FormLabelIcon value="必須" />
              </label>
              <Field
                name="ikejime_method"
                className="ikejime_method"
                component={FieldDropdown}
                selection
                fluid
                options={app.ikejimeMethod}
                placeholder="選択してください"
                disabled={!users.mydata.isSeller}
              />
            </div>
            <div className="field_wrapper">
              <Field
                name="is_ikejime"
                className="w__is_ikejime"
                component={FieldCheckbox}
                label="活〆"
              />
            </div>
            <div className="field_wrapper">
              <label className="flex">
                処理方法
                <FormLabelIcon value="必須" />
              </label>
              <Field
                name="cleaning_method"
                className="area"
                component={FieldDropdown}
                selection
                fluid
                options={app.cleaningMethod}
                placeholder="選択してください"
                disabled={!users.mydata.isSeller}
              />
              <Field
                name="ikejime_water"
                className="ikejime-water-radio"
                component={FieldIkejimeWaterRadio}
                items={app.ikejimeWater}
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                締め処理を行ったタイミング
                <FormLabelIcon value="任意" optional />
              </label>
              <Field
                name="ikejime_timing"
                className="timing"
                selection
                fluid
                options={app.ikejimeTiming}
                placeholder="選択してください"
                disabled={!users.mydata.isSeller}
                component={FieldDropdown}
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                保管方法
                <FormLabelIcon value="必須" />
              </label>
              <Field
                name="saving_method"
                className="save_method"
                selection
                fluid
                options={app.savingMethod}
                placeholder="選択してください"
                disabled={!users.mydata.isSeller}
                component={FieldDropdown}
              />
            </div>

            <div className="field_wrapper">
              <Field
                name="is_vacuum"
                className="w__is-vaccum"
                component={FieldCheckbox}
                label="真空パック保管"
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                配送料の負担
                <FormLabelIcon value="必須" />
              </label>
              <Field
                name="shipping_expense"
                className="defrayment"
                selection
                fluid
                options={app.shippingExpense.filter(
                  ({ value }) =>
                    value !== 3 ||
                    (props.users.mydata.isCompany &&
                      props.users.mydata.shippingProvider !== 0 &&
                      props.shippingCosts.id !== 0 &&
                      props.shippingCosts.user_id !== 0)
                )}
                placeholder="選択してください"
                disabled={!users.mydata.isSeller}
                component={FieldDropdown}
              />
            </div>
            {shipping_expense === 3 ? (
              <div className="field_wrapper">
                <label className="flex">
                  配送サイズ区分
                  <FormLabelIcon value="必須" />
                </label>
                <Field
                  component={FieldDropdown}
                  disabled={false}
                  fluid={true}
                  name="shipping_size"
                  options={app.shippingSize}
                  placeholder="選択してください"
                  selection={true}
                />
                <p style={{ fontSize: 14 }}>
                  ※クール便を利用し、佐川急便を利用する場合、140サイズを選択すると20kgまでの区分の料金が自動計算されます。
                </p>
              </div>
            ) : null}
            <div className="field_wrapper">
              <label className="flex">
                配送方法
                <FormLabelIcon value="必須" />
              </label>
              <Field
                name="delivery_method"
                selection
                fluid
                options={app.deliveryMethod}
                placeholder="選択してください"
                disabled={!users.mydata.isSeller}
                component={FieldDropdown}
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                発送までの日数
                <FormLabelIcon value="必須" />
              </label>
              <Field
                name="shipping_timing"
                selection
                fluid
                options={app.shippingTiming}
                placeholder="選択してください"
                disabled={!users.mydata.isSeller}
                component={FieldDropdown}
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                発送元の地域
                <FormLabelIcon value="必須" />
              </label>
              <Field
                fluid
                className="prefecture"
                name="area"
                component={Dropdown}
                placeholder="選択してください"
                options={areaOptions}
                noResultsMessage="該当する発送元の地域はありません"
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                出品時の開始価格
                <FormLabelIcon value="必須" />
              </label>
              <Field
                name="price"
                placeholder="入力してください"
                label={{ basic: true, content: '¥' }}
                labelPosition="left"
                disabled={!users.mydata.isSeller}
                component={FieldInput}
                maxLength={8}
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                即決価格
                <FormLabelIcon value="任意" optional />
              </label>
              <Field
                name="fix_price"
                placeholder="入力してください"
                label={{ basic: true, content: '¥' }}
                labelPosition="left"
                disabled={!users.mydata.isSeller}
                component={FieldInput}
                maxLength={8}
              />
            </div>

            <div className="field_wrapper">
              <label className="flex">
                出品期限
                <FormLabelIcon value="必須" />
              </label>
              <div className="close_bid_min">
                <Field
                  fluid
                  name="close_bid_min_date"
                  placeholder="選択してください"
                  className="expired"
                  disabled={!users.mydata.isSeller}
                  component={Dropdown}
                  options={app.closeBidMinDate}
                  noResultsMessage="該当する出品期限はありません"
                  type="number"
                />
                <Field
                  fluid
                  name="close_bid_min_hour"
                  placeholder="選択してください"
                  className="expired"
                  disabled={!users.mydata.isSeller}
                  component={Dropdown}
                  options={app.closeBidMinHour}
                  noResultsMessage="該当する出品期限はありません"
                  type="number"
                />
              </div>
            </div>

            <div className="field_wrapper description">
              <label className="flex">
                出品魚説明
                <FormLabelIcon value="必須" />
              </label>
              <Field
                rows={5}
                name="explanation"
                component={FieldTextArea}
                disabled={!users.mydata.isSeller}
                maxLength={1001}
              />
              <span className="word_count_under">(最大1000文字)</span>
            </div>

            <div className="field_wrapper form_last">
              <label className="flex compliance_container">
                遵守事項
                <FormLabelIcon value="必須" />
              </label>
              <div className="compliance_wrap">
                <Field
                  name="red_list_check"
                  className="compliance_checkbox"
                  disabled={!users.mydata.isSeller}
                  component={FieldRedListAgreement}
                />
              </div>
              <div className="compliance_wrap">
                <Field
                  name="rare_spacies_check"
                  className="compliance_checkbox"
                  disabled={!users.mydata.isSeller}
                  component={FieldRareSpeciesAgreement}
                />
              </div>
              <div className="compliance_wrap">
                <Field
                  name="toxic_fish_confirm"
                  className="compliance_checkbox"
                  disabled={!users.mydata.isSeller}
                  component={FiealdToxicFishAgreement}
                />
              </div>
              <div className="compliance_wrap">
                <Field
                  className="compliance_checkbox"
                  name="release_check"
                  component={FieldCheckboxAgreement}
                  disabled={!users.mydata.isSeller}
                  label="未成魚はリリースしてください"
                />
              </div>
            </div>

            <div className="field_wrapper bottom">
              <Button
                fluid
                className="btn_sell"
                disabled={!users.mydata.isSeller || props.isSellLoading}
                loading={props.isSellLoading}
              >
                出品する
              </Button>
            </div>

            <Modal
              className="sell-modal"
              isModal={modalIsOpen}
              handleCloseModal={() => {
                setModalIsOpen(false);
              }}
              ModalBody={SellConfirmModal}
              previewData={previewData}
              mydata={users?.mydata}
              confirmSubmit={confirmSubmit}
              loading={props.isSellLoading}
              showErrorMessage={showErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
            />
          </form>
        )}
      </div>
    </div>
  );
}

export default enhance((props) => <Sell {...props} />);
