import classNames from 'classnames';
import React from 'react';
import logoImage from './logo.png';

import './style.scss';

interface Props {
  appendClassName: string;
  pathname?: string;
}

const CENTERING_PATH = ['/b/signin', '/b/signup'];

const FruitSaleLink: React.FC<Props> = ({ appendClassName, pathname }) => {
  return (
    <div
      className={classNames(`group-link ${appendClassName}`, {
        centering: CENTERING_PATH.includes(pathname || ''),
      })}
    >
      <p>姉妹サイト</p>
      <ul>
        <li>
          <a
            href="https://fruitsale.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={logoImage} alt="FruitSale" className="ui image" />
          </a>
        </li>
        <li>
          <a
            href="https://meatsale.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={'https://meatsale.jp/assets/logo/logo.png'}
              alt="MeatSale"
              className="ui image"
            />
          </a>
        </li>
        <li>
          <a
            href="https://vegetablesale.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={'https://vegetablesale.jp/assets/logo/logo.png'}
              alt="VegetableSale"
              className="ui image"
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FruitSaleLink;
