import { base } from '../../utils';

export const getFollows = async (userId) => {
  try {
    const url = `/v1/users/${userId}/follows`;

    const response = await base.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
