import * as R from 'ramda';
import { formatDate } from '@/utils';

export default function parseActivity({
  id,
  code,
  datetime,
  message,
  context,
  is_read,
}) {
  return Object.assign(
    {},
    {
      id,
      code,
      datetime: formatDate(datetime),
      message,
      context: {
        saleId: R.path(['sale_id'], context),
        saleTitle: R.path(['sale_title'], context),
        userId: R.path(['user_id'], context),
        userName: R.path(['user_name'], context),
      },
      isRead: is_read,
      url: R.path(['sale_id'], context)
        ? `/fishes/${R.path(['sale_id'], context)}`
        : '#',
    }
  );
}
