import { connect } from 'react-redux';
import notify from '@/migration/notification';
import { compose, lifecycle } from 'recompose';
import { bindActionCreators } from 'redux';
import { salesAction, usersAction, appAction } from '@/actions';
import * as queryParams from '@/utils/queryParams';
import { ACTIVITY_CODES } from '@/constants/activityCodes';

import { getActivityPageNumber } from '@/apis/users/userActivies';
const INITIAL_PAGE = 1;

const mapStateToProps = (state) => ({
  sales: state.sales.toJS(),
  form: state.form.toJS(),
  users: state.users.toJS(),
  app: state.app.toJS(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...salesAction, ...usersAction, ...appAction, notify },
    dispatch
  );

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    async componentDidMount() {
      const { getMe, location, history } = this.props;

      const params = queryParams.parse(location.search);
      const code = params.code;
      const uid = params.uid;
      const targetUrl = params.targetUrl;

      try {
        const me = await getMe();
        console.log(params);

        const { data } = await getActivityPageNumber(me.value.id, {
          code: params.code,
          sale_id: params.saleId,
        });

        const page = data?.page_number;
        const targetId = data?.id;
        // ページ番号が1の時
        if (page === INITIAL_PAGE || !page) {
          switch (code) {
            // 評価を受けた
            case ACTIVITY_CODES.REVIEW_RECEIVED:
              history.replace(`/mypage/rerview#${targetId}`);
              break;
            // 評価に対するコメントを受けた場合
            case ACTIVITY_CODES.REVIEW_REPLY_COMMENT_RECEIVED:
              history.replace(`/users/${uid}#${targetId}`);
              break;
            // 上記以外の場合
            default:
              history.replace(targetUrl);
          }
          return;
        }

        switch (code) {
          // 評価を受けた
          case ACTIVITY_CODES.REVIEW_RECEIVED:
            history.replace(`/mypage/rerview?page=${page}#${targetId}`);
            break;
          // 評価に対するコメントを受けた場合
          case ACTIVITY_CODES.REVIEW_REPLY_COMMENT_RECEIVED:
            history.replace(`/users/${uid}?evp=${page}#${targetId}`);
            break;
          default:
            history.replace(targetUrl);
        }
      } catch (error) {
        switch (code) {
          // 評価を受けた
          case ACTIVITY_CODES.REVIEW_RECEIVED:
            history.replace(`/mypage/rerview`);
            break;
          // 評価に対するコメントを受けた場合
          case ACTIVITY_CODES.REVIEW_REPLY_COMMENT_RECEIVED:
            history.replace(`/users/${uid}`);
            break;
          // 上記以外の場合
          default:
            history.replace(targetUrl);
        }
      } finally {
        console.log(2);
      }
    },
  })
);

export default enhance;
