import React from 'react';
import { Field } from 'redux-form/immutable';
import { Button, Header } from 'semantic-ui-react';

import { app, assets } from '@/config';
import { Dropdown } from '../Dropdown';
import enhance from './enhancer';

import './style.scss';
import ListSwitch from '../FishSort/ListSwitch';

function FishSearchForm(props) {
  const { form, handleSubmit, handleSearch } = props;

  return (
    <form className="app_fish_search_form">
      <Header>ほしい魚を探してみましょう！</Header>
      <div className="search-form">
        <ListSwitch />
        <div className="dropdowns flex">
          <Field
            name="statusCategory"
            className="category"
            selection
            fluid
            options={assets.fishes.statusCategory}
            placeholder="カテゴリ"
            component={Dropdown}
          />
          <Field
            fluid
            className="prefecture"
            name="area"
            component={Dropdown}
            placeholder="発送元の地域"
            options={app.area}
            noResultsMessage="該当する発送元の地域はありません"
          />
          <Field
            fluid
            className="prefecture"
            name="sorts"
            component={Dropdown}
            placeholder="並び順"
            options={app.sorts}
          />
          <div className="btn_fish_search flex">
            <Button
              type="button"
              onClick={handleSubmit(handleSearch)}
              content="探す"
              className="btn_fish_search"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default enhance((props) => <FishSearchForm {...props} />);
