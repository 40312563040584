import { compose, setDisplayName, withHandlers, lifecycle } from 'recompose';
import notify from '@/migration/notification';
import loadImage from 'blueimp-load-image';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';

import { constants } from '../../../constants';

const enhance = compose(
  setDisplayName('FieldSellDropzone'),
  withHandlers({
    drop: (props) => async (acceptedFiles) => {
      try {
        const tmpDroppedImagesCount = acceptedFiles.length;
        const newDroppedImagesCount =
          props.input.value.length + tmpDroppedImagesCount;
        if (newDroppedImagesCount > constants.IMAGES.MAX_SELL_UPLOADED_COUNT) {
          notify(
            `1度にアップロードできる画像は最大${constants.IMAGES.MAX_SELL_UPLOADED_COUNT}枚です`,
            'error'
          );
          return;
        }

        const promises = acceptedFiles.map(async (file) => {
          const newFile = new Promise((resolve) => {
            loadImage(
              file,
              async (img) => {
                img.toBlob((blob) => {
                  const blobUrl = URL.createObjectURL(blob);
                  resolve(
                    Object.assign(file, {
                      original: blobUrl,
                      thumb: blobUrl,
                      cropBoxData: {},
                      isCloneImage: false,
                    })
                  );
                });
              },
              {
                orientation: true,
                meta: true,
                contain: true,
                maxWidth: 1000,
              }
            );
          });

          return newFile;
        });

        props.uploadIsImageLoading(true);
        const nextFiles = await Promise.all(promises);

        if (isEmpty(props.input.value)) {
          props.change('sellImages', nextFiles);
        } else {
          props.change('sellImages', [...props.input.value, ...nextFiles]);
        }
        props.uploadIsImageLoading(false);
      } catch (err) {
        throw new Error(err);
      }
    },

    deleteDroppedImage: (props) => (file) => {
      const nextDroppedImages = reject(props.input.value, (f) => {
        if (f.original === file.original) {
          // メモリーリークを防ぐ為、ローカルのblobUrlを削除
          URL.revokeObjectURL(f.original);
          URL.revokeObjectURL(f.thumb);
        }
        return f.original === file.original;
      });

      props.change('sellImages', nextDroppedImages);
    },

    handleClick: (props) => (file) => {
      const order = [file];
      for (let i = 0; i < props.input?.value?.length; i++) {
        const f = props.input?.value?.[i];
        if (file.path === f.path) continue;
        order.push(f);
      }

      props.change('sellImages', order);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const inputFiles = this.props?.input?.value;

      if (inputFiles && Array.isArray(inputFiles)) {
        const nextFiles = inputFiles?.map((file) => {
          return {
            original: file,
            thumb: file,
            cropBoxData: {},
            path: file,
            isCloneImage: true,
          };
        });
        this.props.change('sellImages', nextFiles);
      }
    },
  })
);

export default enhance;
