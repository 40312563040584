import { httpClient } from '@/utils/http';

const resouce = '/v1/preview';

export const sellPreview = async (sellData) => {
  try {
    const client = await httpClient();
    const { data } = await client.post(resouce, sellData);

    return { data };
  } catch (error) {
    throw new Error(error);
  }
};
