import * as React from 'react';
import Card from '@/components/Common/Card';
import enhance from './enhancer';
import { availableFishCards } from './utils';
import { useRecoilValue } from 'recoil';
import { listViewStore } from '@/store';
import { ListItem } from '../ListItem';

function SalesList(props) {
  const { data } = props;

  const INVALID_VALUE = 2147483647;
  const listView = useRecoilValue(listViewStore);

  const isSwitch = (() => {
    const path = window.location.pathname;
    if (path.includes('/users')) {
      return true;
    }

    if (path.includes('/mypage')) {
      return true;
    }

    return false;
  })();

  return (
    <>
      {listView === 0 || isSwitch ? (
        <div id="observable_fishes_view" className="app_sales_list flex">
          {availableFishCards(data) &&
            data.map((item) => (
              <Card
                key={item.id}
                id={item.id}
                fishKind={item.fishKind || ''}
                imageUrl={item.imageUrl || item.imageURL}
                sellerName={item.sellerName}
                sellerCompany={item.sellerCompany}
                fixPrice={INVALID_VALUE === item.fixPrice ? '-' : item.fixPrice}
                isCompany={item.isCompany}
                displayPrice={item.displayPrice}
                bidCount={item.bidCount}
                fixed={item.fixed}
                closeBidAtTemp={item.closeBidAtTemp}
                fishingSpot={item.fishingSpot}
                weight={item.weight}
                isNature={item.isNature}
                shippingExpense={item.shippingExpense}
                bid={item.bid}
                closed={item.closed}
                closedReason={item.closedReason}
                commentAdd={item.commentAdd}
                imageAdd={item.imageAdd}
              />
            ))}
        </div>
      ) : (
        <div
          style={{
            padding: `0 1em`,
          }}
        >
          {availableFishCards(data) &&
            data.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  data={{
                    ...item,
                    fixPrice:
                      INVALID_VALUE === item.fixPrice ? '-' : item.fixPrice,
                  }}
                />
              );
            })}
        </div>
      )}
    </>
  );
}

export default enhance((props) => <SalesList {...props} />);
