export const ACTIVITY_TEMPLATES = {
  USER_SIGNUP:
    'Fish Saleにご登録ありがとうございます！早速、オークションに参加しましょう！',
  SELLER_BIDDED: '<sale_title>が<user_name>さんに入札されました！',
  SELLER_SALE_FIXED:
    '<sale_title>が落札されました！出品魚画面に送付先住所が記載されているので、そちらを確認し、発送してください。また、発送する際は商品外装に商品名称、釣った地域、養殖か天然かを記載してください。',
  SELLER_RECIEVED_MESSAGE:
    '<user_name>さんから新しいメッセージが届いています！',
  SELLER_SALE_EXPIRED:
    '<sale_title>に有効な入札がなく、出品期限が過ぎましたので、オークションを終了します。',
  ANOTHER_USER_BIDDED: '<sale_title>に入札がありました！',
  ANOTHER_USER_BIDDED_HIGHER: '<sale_title>に入札がありました！',
  USER_FIXED_SALE:
    '<sale_title>を落札しました！何か気になることがあれば、出品魚画面のメッセージ機能で問い合わせてみましょう。',
  USER_RECIEVED_MESSAGE: '<user_name>さんから新しいメッセージが届いています！',
  ANOTHER_USER_FIXED_SALE: '<sale_title>を<user_name>さんが落札しました！',
  ADMIN_CLOSED_SALE:
    '<sale_title>は運営者により削除されました。禁止されている画像または文言が含まれていた可能性があります。出品の際は、細心の注意を払って、画像及び文言の記載のほど、お願い致します。',
  SELLER_WITHDRAW_SALE:
    '<sale_title>の出品は取り下げられました。他の魚を探してみましょう！',
  PAYMENT_FAILURE:
    '<sale_title>の落札に失敗しました。決済エラー詳細をご確認ください。',
  SELLER_SEND_MESSAGE: '<sale_title> に <user_name> さんがコメントしました！',
  FOLLOW_USER_SELL: '<user_name> さんが<sale_title> を出品しました！',
  USER_SELL: '<user_name> さんが <sale_title> を出品しました！',
  REVIEW_RECEIVED:
    '<user_name> さんから <sale_title> に関して評価を受けました！',
  REVIEW_REPLY_COMMENT_RECEIVED:
    '<user_name> さんから <sale_title> に関して評価に対するコメントを受けました！',
};
