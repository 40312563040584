import { handleActions } from 'redux-actions';
import { ActionType } from 'redux-promise-middleware';
import { salesAction } from '../actions';
import { Sales } from '../models';

export default handleActions(
  {
    [`${salesAction.searchSales}_${ActionType.Fulfilled}`](state, action) {
      return state.searchSales(state, action);
    },
    [`${salesAction.getSales}_${ActionType.Fulfilled}`](state, action) {
      return state.getSales(state, action);
    },

    // Deprecated 魚一覧 / すべて
    [`${salesAction.getSalesWithPagination}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getSalesWithPagination(state, action);
    },

    // サイドメニュー 魚一覧 / すべて
    [`${salesAction.getSalesPagination}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getSalesPagination(state, action);
    },

    // 魚一覧 / 会社
    [`${salesAction.getSalesCompany}_${ActionType.Fulfilled}`](state, action) {
      return state.getSalesCompany(state, action);
    },

    [`${salesAction.getUnratedWithPagination}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getUnratedWithPagination(state, action);
    },
    [`${salesAction.getOwnExhibitsCounts}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getOwnExhibitsCounts(state, action);
    },
    [salesAction.updateCommentsPagesActive](state, action) {
      return state.updateCommentsPagesActive(state, action);
    },
    [salesAction.updateCommentsBeforePagesActive](state, action) {
      return state.updateCommentsBeforePagesActive(state, action);
    },
    [salesAction.updateUserProfilePageActive](state, action) {
      return state.updateUserProfilePageActive(state, action);
    },
    [salesAction.updateMyPagePagesActive](state, action) {
      return state.updateMyPagePagesActive(state, action);
    },
    [salesAction.initializeSalesForMyPage](state, action) {
      return state.initializeSalesForMyPage(state, action);
    },
    [`${salesAction.getSaleCommentsWithPagination}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getSaleCommentsWithPagination(state, action);
    },
    [`${salesAction.getSaleCommentsBeforePagination}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getSaleCommentsBeforePagination(state, action);
    },
    [salesAction.updateFishesPagesActive](state, action) {
      return state.updateFishesPagesActive(state, action);
    },
    [`${salesAction.getSalesForMyPage}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getSalesForMyPage(state, action);
    },
    [`${salesAction.getSalesForUserProfile}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getSalesForUserProfile(state, action);
    },
    [`${salesAction.getSaleBids}_${ActionType.Fulfilled}`](state, action) {
      return state.getSaleBids(state, action);
    },
    [`${salesAction.getSaleById}_${ActionType.Fulfilled}`](state, action) {
      return state.getSaleById(state, action);
    },
    [`${salesAction.postSale}_${ActionType.Fulfilled}`](state, action) {
      return state.postSale(state, action);
    },
    [`${salesAction.getSalesForLanding}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.getSalesForLanding(state, action);
    },
    [salesAction.postSaleBids](state, action) {
      return state.postSaleBids(state, action);
    },
    [`${salesAction.updateBids}_${ActionType.Fulfilled}`](state, action) {
      return state.updateBids(state, action);
    },
    [`${salesAction.getSaleComments}_${ActionType.Fulfilled}`](state, action) {
      return state.getSaleComments(state, action);
    },
    [`${salesAction.postSaleComment}_${ActionType.Fulfilled}`](state, action) {
      return state.postSaleComment(state, action);
    },
    [`${salesAction.postSaleCommentBefore}_${ActionType.Fulfilled}`](
      state,
      action
    ) {
      return state.postSaleCommentBefore(state, action);
    },
    [`${salesAction.deleteSale}_${ActionType.Fulfilled}`](state, action) {
      return state.deleteSale(state, action);
    },
    [`${salesAction.getSalesBySeller}_${ActionType.Fulfilled}`](state, action) {
      return state.getSalesBySeller(state, action);
    },
    [salesAction.cleanSaleComments](state, action) {
      return state.cleanSaleComments(state, action);
    },

    [`${salesAction.getSalesOwnerAll}_${ActionType.Pending}`](state, action) {
      return state.getSalesOwnerAll(state, action);
    },
    [`${salesAction.getSalesOwnerAll}_${ActionType.Fulfilled}`](state, action) {
      return state.getSalesOwnerAll(state, action);
    },
  },
  new Sales()
);
