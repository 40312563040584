import * as React from 'react';
import { Image } from 'semantic-ui-react';
import classNames from 'classnames';
import { constants } from '../../../../constants';

function LineShareButton(props) {
  const isDesktop = props.type === 'desktop';
  const isMobile = props.type === 'mobile';
  return (
    <a
      href={`${constants.SNS_SHARE_URL.line}?` + window.location.href}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames({
        grid_line_container: isDesktop,
      })}
    >
      <Image
        src="https://fishsale.jp/assets/fs_fish_icon_line.svg"
        className={classNames({
          view_icon_line: isDesktop,
          main_icon_line: isMobile,
        })}
      />
    </a>
  );
}

export default LineShareButton;
