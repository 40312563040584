import { useMemo } from 'react';
import moment from 'moment';
import { getLocaleStringLocales, getDeliveryPrices } from '@/utils';

const MAX_PRICE = 2147483647;

export const useSection = ({
  fishingDay,
  shippingProvider,
  fixPrice,
  isLandAfterFix,
  area,
  prefecture,
  size,
  isCompany,
}) => {
  const now = useMemo(() => {
    return moment().format('YYYY.MM.DD (dd) HH:mm');
  }, []);

  const fishingDate = useMemo(() => {
    if (isLandAfterFix) return '落札後水揚げ';

    if (!fishingDay) return '';

    return moment(fishingDay).format('YYYY.MM.DD (dd)');
  }, [fishingDay, isLandAfterFix]);

  const providerName = useMemo(() => {
    if (!isCompany) return '';

    switch (shippingProvider) {
      case 1:
        return '佐川急便';
      case 2:
        return 'ヤマト運輸';
      case 3:
        return '日本郵便';
      default:
        return '';
    }
  }, [shippingProvider, isCompany]);

  const decisionPrice = useMemo(() => {
    if (!fixPrice || fixPrice >= MAX_PRICE) return '-';

    return `¥${getLocaleStringLocales(fixPrice)}`;
  }, [fixPrice]);

  const deliveryPrice = useMemo(() => {
    return getDeliveryPrices(area, prefecture, size);
  }, [area, prefecture, size]);

  return { now, fishingDate, providerName, decisionPrice, deliveryPrice };
};
