import React from 'react';

const MediaPublication = () => {
  return (
    <div className="media">
      <div className="media-header">メディア掲載</div>
      <div className="media-content">
        <ul>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/tokudane.jpg"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E6%9C%9D%E6%97%A5%E6%96%B0%E8%81%9E.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/LINE%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9+.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/YOMIURI+ONLINE.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/Infoseek%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9+.jpg"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/SANSPO.COM+.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/SankeiBiz.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/excite.%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/NewsPicks.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E8%B2%A1%E7%B5%8C%E6%96%B0%E8%81%9E.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/BIGLOBE%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9+.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%83%88%E3%83%AC%E3%83%B3%E3%83%88%E3%82%99%E3%82%BF%E3%82%A4%E3%83%A0%E3%82%B9.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%83%92%E3%82%99%E3%82%B7%E3%82%99%E3%83%8D%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%A0%E3%82%B9.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/G%E3%82%AF%E3%82%99%E3%83%AB%E3%83%A1press.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%83%95%E3%83%BC%E3%83%BC%E3%83%BC%E3%83%88%E3%82%99%EF%BC%81%EF%BC%81%EF%BC%81.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/Mapion%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9+.gif"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%40nifty+%E3%83%92%E3%82%99%E3%82%B7%E3%82%99%E3%83%8D%E3%82%B9.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/Ba-ter.News+png.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/DIGINEWS+.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/NEWSCAST.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E5%BE%B3%E5%B3%B6%E6%96%B0%E8%81%9E.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/StartHome.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%83%8B%E3%82%B3%E3%83%8B%E3%82%B3%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%8D%E3%83%83%E3%83%88%E3%82%B3%E3%83%A0+.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/zakzak.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%81%88%E3%82%93%E3%82%A6%E3%83%81.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%83%8D%E3%82%BF%E3%83%95%E3%83%AB.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/RBBTODAY.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/N%2BNewsRelease+.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%81%88%E3%82%93%E9%A3%9F%E3%81%B8%E3%82%99.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9%E3%83%BB%E3%82%A2%E3%83%8B%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%99.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%82%B3%E3%83%9F%E3%83%A5%E3%83%8B%E3%83%86%E3%82%A3%E3%83%BB%E3%83%9B%E3%82%9A%E3%82%B9%E3%83%88.jpg"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E6%9D%B1%E4%BA%AC%E3%83%92%E3%82%99%E3%83%A5%E3%83%BC%E3%83%86%E3%82%A3%E3%83%BC+.png"
              width="150"
            />
          </li>
          <li>
            <img
              src="https://fishsale.jp/assets/publication_media/%E3%83%AD%E3%82%A4%E3%82%BF%E3%83%BC.jpeg"
              width="150"
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MediaPublication;
