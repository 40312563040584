import * as React from 'react';
import { Map } from 'immutable';
import { deliveryTime } from './utils';
import { Field } from 'redux-form/immutable';
import { Dropdown, FormLabelIcon } from '@/components/Common';
import { FieldInput } from '../../../Form';

function Myshippingdata({
  fields,
  ids,
  change,
  deleteShippingData,
  searchAddress,
}) {
  return (
    <div>
      <button
        onClick={() =>
          fields.push(
            Map({
              addressBefore: '',
              addressAfter: '',
              delivery_time: '',
              full_name: '',
              phone_number: '',
              post_number: '',
            })
          )
        }
        className="button button__add"
        disabled={fields.length >= 5}
        type="button"
      >
        追加
      </button>

      {fields.map((myshippingdata, index) => {
        const canEdit = ids[index] === undefined;
        const inputText = ({ input: { value } }) => <div>{value}</div>;
        const dropdownText = ({ input: { value } }) => {
          const { text } = deliveryTime.find(
            ({ value: deliveryTimeValue }) => value === deliveryTimeValue
          );

          return <div>{text}</div>;
        };

        return (
          <div key={index} className="b__form-card">
            <div>
              <div className="form_item">
                <div className="flex_required">
                  <label>送付先名</label>
                  <span className="word_count">(最大20文字)</span>
                  <FormLabelIcon value="必須" />
                </div>
                <Field
                  name={`${myshippingdata}.full_name`}
                  className="full_name"
                  placeholder="例) 山田太郎"
                  component={canEdit ? FieldInput : inputText}
                  maxLength={20}
                  onChange={(_, value) => {
                    change(`${myshippingdata}.full_name`, value);
                    change('confirm_address', false);
                  }}
                />
              </div>
              <div className="form_item">
                <div className="flex_required">
                  <label>郵便番号</label>
                  <FormLabelIcon value="必須" />
                </div>

                <div className="b__post-number">
                  <div className="b__post-number_inner">
                    <Field
                      name={`${myshippingdata}.top_post_number`}
                      placeholder="123"
                      component={canEdit ? FieldInput : inputText}
                      maxLength={3}
                      type="text"
                      onChange={(_, value) => {
                        change(`${myshippingdata}.top_post_number`, value);
                        change('confirm_address', false);
                      }}
                    />
                    <div className="unit_hyphen">-</div>
                    <Field
                      name={`${myshippingdata}.bottom_post_number`}
                      placeholder="4567"
                      component={canEdit ? FieldInput : inputText}
                      maxLength={4}
                      type="text"
                      onChange={(_, value) => {
                        change(`${myshippingdata}.bottom_post_number`, value);
                        change('confirm_address', false);
                      }}
                    />
                  </div>

                  {canEdit ? (
                    <button
                      type="button"
                      className="post-search-btn"
                      onClick={async () => {
                        await searchAddress(index);
                      }}
                    >
                      住所検索
                    </button>
                  ) : null}
                </div>
              </div>

              <div className="hidden_form">
                <Field
                  name={`${myshippingdata}post_number`}
                  component={FieldInput}
                />
              </div>

              <div className="form_item space">
                <div className="flex_required">
                  <label>住所</label>
                  <span className="word_count">(最大50文字)</span>
                  <FormLabelIcon value="必須" />
                </div>
                <Field
                  name={`${myshippingdata}.addressBefore`}
                  className="addressBefore"
                  placeholder="例) 大阪府大阪市"
                  component={canEdit ? FieldInput : inputText}
                  disabled={true}
                />
                <Field
                  name={`${myshippingdata}.addressAfter`}
                  className="addressAfter"
                  placeholder="例) 西区１−２ フィッシュビル3F"
                  component={canEdit ? FieldInput : inputText}
                  onChange={() => {
                    change('confirm_address', false);
                  }}
                  maxLength={50}
                />
                <div className="form_item">
                  <div className="flex_required">
                    <label>携帯電話番号</label>
                    <FormLabelIcon value="必須" />
                  </div>
                  <Field
                    name={`${myshippingdata}.phone_number`}
                    className="phone_number"
                    placeholder="例) 00011110000"
                    component={canEdit ? FieldInput : inputText}
                    maxLength={11}
                    type="text"
                    onChange={(_, value) => {
                      change(`${myshippingdata}.phone_number`, value);
                      change('confirm_address', false);
                    }}
                  />
                </div>
                <p className="address_note">※ハイフン無しで入力して下さい。</p>
                <div className="form_item">
                  <div className="flex_required">
                    <label>配達指定時間</label>
                    <FormLabelIcon value="必須" />
                  </div>
                  <Field
                    search
                    fluid
                    className="delivery_time"
                    name={`${myshippingdata}.delivery_time`}
                    component={canEdit ? Dropdown : dropdownText}
                    onChange={() => {
                      change('confirm_address', false);
                    }}
                    placeholder="配達指定時間"
                    options={deliveryTime}
                    noResultsMessage="該当する配達指定時間はありません"
                  />
                </div>
              </div>
              <button
                onClick={async () => {
                  if (ids[index]) {
                    await deleteShippingData(ids[index]);
                  } else {
                    fields.remove(index);
                  }
                }}
                className="button button__delete"
                type="button"
              >
                削除
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Myshippingdata;
