// DEPRECATED

import {
  makeQueryParam,
  paginationQueryBuilder,
  queryBuilder,
  isHttpStatusOK,
} from '@/apis/utils';

import { conditionQueryBuilder } from './utils';

import { getEnv } from '@/utils';

const { GO_ENDPOINT } = getEnv();

export default async function getSalesWithPagination(
  pagination,
  conditions,
  sort
) {
  if (typeof pagination === 'undefined') {
    throw new Error(`must be pagination is object.`);
  }

  try {
    const sortq = makeQueryParam('sort', sort.join(''));
    const query = queryBuilder(
      paginationQueryBuilder(pagination),
      conditionQueryBuilder(conditions),
      sortq
    );

    const response = await fetch(`${GO_ENDPOINT}/v1/sales_paging${query}`, {
      method: 'GET',
    });

    if (isHttpStatusOK(response.status)) {
      const json = await response.json();
      const totalCount = Number(response.headers.get('x-total-count'));
      // Paginationのロジックでは使わないので注意。
      const totalPages = Number(response.headers.get('x-total-pages'));

      return Object.assign(json, { totalCount, totalPages });
    }

    throw new Error(response);
  } catch (error) {
    console.error(error);
    return error;
  }
}
