import { base } from '../../utils';

export const getFollowPagination = async (pagination, sort) => {
  try {
    const limit = pagination.limit;
    const current = pagination.current;
    const userId = pagination.userId;

    const url = `/v1/users/${userId}/follows_paging?limit=${limit}&page=${current}&sort=${sort}&user_id=${userId}`;

    const response = await base.get(url);

    const result = {
      pages: response.data.pages,
      totalCount: Number(response.headers['x-total-count']),
      totalPages: Number(response.headers['x-total-pages']),
      current,
    };
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
