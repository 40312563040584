import formatDate from '@/apis/utils/formatDate';
import { date } from '@/utils';

export default function parseSale({
  sale_id,
  bought_user_id,
  serller_name,
  seller_company,
  seller_user_id,
  size,
  weight,
  weight_additional_info,
  area_text,
  spot_text,
  hide_fishing_spot,
  fixed,
  fishing_spot_detail,
  method_text,
  expense_text,
  timing_text,
  fix_price,
  close_bid_at,
  posted_at,
  price,
  display_price,
  bid,
  bid_count,
  bidder_user_id,
  bidder_name,
  fish_kind,
  explanation,
  images,
  is_nature,
  is_ikejime,
  ikejime_water,
  water_text,
  closed,
  closed_reason,
  closed_at,
  delivery_text,
  ikejime_text,
  fishing_day,
  save_text,
  is_vacuum,
  bought_user_name,
  user_rated,
  fixed_at,
  cleaning_text,
  last_updated_at,
  stripe_error_message,
  shipping_size,
  shipping_expense,
  shipping_provider,
  comment_add,
  image_add,
}) {
  const nextCloseBidAt = formatDate(close_bid_at);
  const nextPostedAt = formatDate(posted_at);
  const nextClosedAt = formatDate(closed_at);
  const fishingDay = date.formatDate(fishing_day);

  let nextImageURL = '';

  // imagesの長さが0よりも大きければ配列の0番目を代入する
  if (images && images.length > 0) {
    nextImageURL = images[0];
  }

  return Object.assign(
    {},
    {
      id: sale_id,
      isNature: is_nature,
      isIkejime: is_ikejime,
      boughtUserId: bought_user_id,
      sellerName: serller_name,
      sellerCompany: seller_company,
      sellerUserId: seller_user_id,
      size,
      weight,
      weightAdditionalInfo: weight_additional_info,
      area: area_text,
      fishingSpot: spot_text,
      hideFishingSpot: hide_fishing_spot,
      fixed,
      fishingSpotDetail: fishing_spot_detail,
      methodText: method_text,
      expenseText: expense_text,
      timingText: timing_text,
      fixPrice: fix_price,
      closeBidAt: nextCloseBidAt,
      closeBidAtTemp: close_bid_at,
      postedAt: nextPostedAt,
      price,
      displayPrice: display_price,
      bid,
      bidCount: bid_count,
      bidderUserId: bidder_user_id,
      bidderName: bidder_name,
      fishKind: fish_kind,
      explanation,
      imageURL: nextImageURL,
      images,
      closed,
      closedReason: closed_reason,
      closedAt: nextClosedAt,
      deliveryText: delivery_text,
      ikejimeTimingText: ikejime_text,
      ikejimeWater: ikejime_water,
      waterText: water_text,
      fishingDay,
      saveText: save_text,
      isVacuum: is_vacuum,
      boughtUserName: bought_user_name,
      userRated: user_rated,
      fixedAt: fixed_at,
      cleaningText: cleaning_text,
      lastUpdatedAt: last_updated_at,
      stripeErrorMessage: stripe_error_message,
      shippingSize: shipping_size,
      shippingExpense: shipping_expense,
      shippingProvider: shipping_provider,
      commentAdd: comment_add,
      imageAdd: image_add,
    }
  );
}
