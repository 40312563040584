const endpoint = 'https://assets.fishsale.jp';

export default {
  securityCode: endpoint + '/security-code.png',
  banner_1: endpoint + '/logo/banner_1%403x.png',
  banner_2: endpoint + '/logo/banner_2%403x.png',
  banner_3: endpoint + '/logo/banner_3%403x.png',
  banner_4: endpoint + '/logo/banner_4%403x.png',
  logo_header: endpoint + '/logo/logo.png',
  logo_header_mobile: endpoint + '/logo/logo-header-mobile.png',
  logo_footer: endpoint + '/logo/logo-02.png',
  facebookLogo: endpoint + '/logo/logo%40facebook.png',
  googleLogo: endpoint + '/logo/logo%40google.png',
  stripeLogo: endpoint + '/logo/logo%40stripe.png',
};
