import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';

function FieldIsNature(props) {
  const { meta, input } = props;

  return (
    <div className="is-nature-radio-wrapper">
      <Checkbox
        {...props}
        radio
        onChange={(e, { checked }) => input.onChange(checked)}
        checked={input.value}
        id="sell-is-nature"
      />
      <label htmlFor="sell-is-nature" className="fish_select">
        天然
      </label>

      <Checkbox
        {...props}
        radio
        onChange={(e, { checked }) => input.onChange(!checked)}
        checked={!input.value}
        id="sell-is-not-nature"
      />
      <label htmlFor="sell-is-not-nature" className="fish_select">
        養殖
      </label>
      <br />
      {meta.touched && meta.error && (
        <a id="error">
          <span className="redux_form error">{meta.error}</span>
        </a>
      )}
    </div>
  );
}

export default FieldIsNature;
