import axios from 'axios';

import { getEnv, Auth } from '@/utils';

const { GO_ENDPOINT } = getEnv();

async function getUserShipping(userId) {
  try {
    const token = await Auth.getIdToken();
    const response = await axios
      .create({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .get(`${GO_ENDPOINT}/v1/users/${userId}/shipping`);

    return response.data;
  } catch (error) {
    return error;
  }
}

export default getUserShipping;
