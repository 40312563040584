export const statusCategory = [
  {
    key: '1',
    value: 'all',
    text: '全ての魚',
  },
  {
    key: '2',
    value: 'canBidding',
    text: '入札可能な魚',
  },
  {
    key: '3',
    value: 'onDisplay',
    text: '未入札の魚',
  },
  {
    key: '4',
    value: 'bidding',
    text: 'オークション中の魚',
  },
  {
    key: '5',
    value: 'alreadyCompletedBidding',
    text: '落札済みの魚',
  },
  {
    key: '6',
    value: 'biddingComplete',
    text: 'オークションが終了した魚',
  },
];

export const fromDay = [
  {
    key: '0',
    value: '0',
    text: '指定なし',
  },
  {
    key: '1',
    value: '1',
    text: '1ヶ月前',
  },
  {
    key: '2',
    value: '3',
    text: '3ヶ月前',
  },
  {
    key: '3',
    value: '6',
    text: '6ヶ月前',
  },
  {
    key: '4',
    value: '12',
    text: '12ヶ月前',
  },
];

export const areas = [
  {
    key: '1',
    value: '0',
    text: '全ての地域',
  },
  {
    key: '2',
    value: '1',
    text: '北海道地方',
  },
  {
    key: '3',
    value: '2',
    text: '東北地方',
  },
  {
    key: '4',
    value: '3',
    text: '関東地方',
  },
  {
    key: '5',
    value: '4',
    text: '中部地方',
  },
  {
    key: '6',
    value: '5',
    text: '近畿地方',
  },
  {
    key: '7',
    value: '6',
    text: '中国・四国地方',
  },
  {
    key: '8',
    value: '7',
    text: '九州地方',
  },
  {
    key: '9',
    value: '8',
    text: '沖縄地方',
  },
];

export const sorts = [
  {
    key: 'from_new_arrival',
    text: '新着順',
    value: '-biddable-fixed-created_at',
  },
  {
    key: 'from_cheap',
    text: '価格の安い順',
    value: '-biddable-fixed+display_price-created_at',
  },
  {
    key: 'from_expensive',
    text: '価格の高い順',
    value: '-biddable-fixed-display_price-created_at',
  },
  {
    key: 'from_shorter',
    text: '残り時間の短い順',
    // value: '-biddable-fixed+close_bid_at-created_at',
    value: '-biddable-fixed_at+close_bid_at',
  },
  {
    key: 'from_longer',
    text: '残り時間の長い順',
    value: '-biddable-fixed-close_bid_at-created_at',
  },
  {
    key: 'from_cheap_buy_it_now',
    text: '即決価格の安い順',
    value: '-biddable-fixed+fix_price-created_at',
  },
  {
    key: 'from_expensive_buy_it_now',
    text: '即決価格の高い順',
    value: '-biddable-fixed-fix_price-created_at',
  },
  {
    key: 'from_more_count',
    text: '入札件数の多い順',
    value: '-biddable-fixed-bid_count-created_at',
  },
  {
    key: 'from_less_count',
    text: '入札件数の少ない順',
    value: '-biddable-fixed+bid_count-created_at',
  },
];

export const compnaySorts = [
  {
    key: 'total_evaluation_count',
    text: 'レビュー件数順',
    value: '-total_evaluation_count',
  },
  {
    key: 'average_point',
    text: 'レビュー評価順',
    value: '-average_point',
  },
  {
    key: 'total_proceeds',
    text: '売上順',
    value: '-total_proceeds',
  },
  {
    key: 'follower_count',
    text: 'フォロワー数順',
    value: '-follower_count',
  },
  {
    key: 'monthly_view_count',
    text: '注目度順',
    value: '-monthly_view_count',
  },
  {
    key: 'weekly_view_count',
    text: '急上昇順',
    value: '-weekly_view_count',
  },
];
