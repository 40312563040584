import React from 'react';
import FishesPagination from '../FishesPagination';
import SalesList from '../SalesList/SalesList';
import { Loader } from 'semantic-ui-react';

const FishesContent = ({
  isLoading,
  active,
  me,
  totalPages,
  currentPage,
  handleChangePage,
}) => {
  if (isLoading) {
    return (
      <div className="b__fishes-content__loading">
        <Loader
          active={isLoading}
          inline="centered"
          className="fished_loading"
        />
      </div>
    );
  }

  if (!active.length) {
    return (
      <div className="b__fishes-content__empty">
        <span>対象の魚は存在しません</span>
      </div>
    );
  }

  return (
    <div>
      <SalesList data={active} me={me} />
      <FishesPagination
        totalPages={totalPages}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};

export default FishesContent;
